import { onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

export function useGlobalSetup() {
    const route = useRoute(); // Следим за сменой страниц

    const initGlobalScripts = () => {
        setTimeout(() => {
            const main = document.querySelector('#mainScreen');
            const header = document.querySelector('.header');

            if (header && main) {
                let headerHeight = header.offsetHeight;
                main.style.paddingTop = `${headerHeight}px`;

                const updateHeaderState = () => {
                    let scrollDistance = window.scrollY + headerHeight;
                    if (scrollDistance > headerHeight) {
                        header.classList.add('header--fixed');
                    } else {
                        header.classList.remove('header--fixed');
                    }
                };

                // Проверяем сразу при загрузке
                updateHeaderState();

                window.addEventListener('scroll', updateHeaderState);
            }

            document.querySelectorAll('[data-name-anchor]').forEach(anchor => {
                anchor.addEventListener('click', (event) => {
                    event.preventDefault();
                    const blockId = anchor.dataset.nameAnchor;
                    const block = document.getElementById(blockId);
                    if (block) {
                        const elementPosition = block.getBoundingClientRect().top;
                        const offsetPosition = elementPosition - header.offsetHeight;
                        window.scrollBy({
                            top: offsetPosition,
                            behavior: 'smooth'
                        });
                    }
                });
            });
        }, 100); // Даем время элементам загрузиться
    };

    onMounted(() => {
        initGlobalScripts();
    });

    watch(() => route.fullPath, () => {
        initGlobalScripts(); // Перезапускаем скрипт при смене страницы
    });
}
