<template>
  <div>
    <router-view></router-view>

    <section class="section-title">
      <div class="container">
        <h2 class="title-opacity text-transperent">Greenfeild Club</h2>
        <button class="scroll-top" id="scroll-top" @click="scrollToTop">
          Наверх
        </button>
      </div>
    </section>
    <footer class="footer">
      <div class="container">
        <div class="footer__container">
          <a @click="redirectToHomePage('link')" href="#" class="logo"><img loading='lazy' width="216" height="102" src="@/assets/img/logo.webp" srcset="@/assets/img/logo@2x.webp 2x" alt="logotip"></a>
          <div>
            <div class="footer-link-group">
              <a :href="rulesUrl" class="header__rules" @click="handleRulesButtonClick()" target="_blank">
                <span>Правила акции</span>
                <svg width='20' height='20'><use href='@/assets/img/icons/icons.svg#download'></use></svg>
              </a>

              <a :href="policyUrl" class="header__rules" @click="handleRulesButtonClick()" target="_blank">
                <span>Пользовательское соглашение</span>
              </a>
            </div>
            <p>Для участия в акции необходимо: в период с 05 февраля 2025 г. по 24 декабря 2025. приобрести в любом магазине на территории РФ любую упаковку чая Greenfield ®, зарегистрировать чек с покупкой на сайте www.greenfield-club.ru или в чат-боте @greenfieldworld_bot, накопить баллы (каждые 10 рублей равняются 1 баллу) и обменять их на доступные подарки, участвовать в розыгрыше призов. Общий срок проведения акции (включая срок выдачи подарков): с 05 февраля 2025 г. по 31 декабря 2025 г. Количество призов и подарков ограничено. Призы и подарки могут отличаться от изображений на рекламных материалах. Информация об организаторе акции, сроках и правилах ее проведения, количестве подарков, сроках, месте и порядке их вручения размещена на сайте www.greenfield-club.ru. Организатор акции — ООО «Эктив Проджект Менеджмент» (ООО «АРМ»), ИНН 7705609429.</p>
          </div>
        </div>
      </div>
    </footer>

    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay" @click="closeLoginPopup"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="modal-close">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <h5 class="modal-title">Авторизация</h5>
          <form class="form form-grid" @submit.prevent="login" autocomplete="off">
            <div class="form__block-inputns">
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input class="form__input" type="text" name="email" v-model="email" placeholder="" required>
                  <span class="form__name">Почта</span>
                  <span v-if="loginFormErrors.email" class="error-message">{{ loginFormErrors.email }}</span>
                </div>
              </label>
              <label class="form__wrap">
                <div class="form__inner-input">
                    <span class="form__icon-eye" @click="togglePasswordVisibility">
                      <img
                          :src="passwordVisible ? eyeOffIcon : eyeIcon"
                          width="24"
                          height="24"
                      />
                    </span>
                  <input class="form__input" :type="passwordVisible ? 'text' : 'password'" maxlength="255" name="password" v-model="password"  placeholder="" required>
                  <span class="form__name">Пароль</span>
                </div>
                <button class="form__text-btn --forget" type="button" @click="openResetPasswordPopup">Забыли пароль?</button>
              </label>
            </div>
            <div class="form__footer">
              <div class="error-message" v-if="responseError">{{ responseError }}</div>
              <button class="send-button form__btn btn"
                      :class="{'--loader-active': formSending}" type="submit">Войти</button>
              <div class="form__btn-wrapper">
                <span class="form__text-btn">Нет аккаунта?</span>
                <button
                    class="send-button form__btn btn"
                    :class="{'--loader-active': formSending}"
                    @click="openRegisterPopup"
                    :disabled="state.promoStatus === 'end'"
                    type="button"
                >
                  Регистрация
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopup"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="modal-close">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <h5 class="modal-title">Регистрация</h5>
          <form class="form form-grid" @submit.prevent="register" autocomplete="off">
            <div class="form__block-inputns">
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input class="form__input"
                         maxlength="255"
                         v-model="registerForm.first_name"
                         @input="removeSpacesFirstName"
                         type="text"
                         name="first_name"
                         placeholder=""
                         autocomplete="off"
                         @blur="clearError('first_name')"
                         :class="{ 'is-not-valid': errors.first_name }"
                         required>
                  <span class="form__name">Имя</span>
                  <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
                </div>
              </label>
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input class="form__input"
                         maxlength="255"
                         v-model="registerForm.last_name"
                         @input="removeSpacesLastName"
                         name="last_name"
                         type="text"
                         placeholder=""
                         required>
                  <span class="form__name">Фамилия</span>
                  <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
                </div>
              </label>
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input class="form__input"
                         type="text"
                         placeholder=""
                         required
                         maxlength="18"
                         v-model="registerForm.phone_number"
                         name="phone_number"
                         autocomplete="off"
                         @blur="clearError('phone_number')"
                         :class="{ 'is-not-valid': errors.phone_number }" v-mask>
                  <span class="form__name">Номер телефона</span>
                  <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
                </div>
              </label>
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input class="form__input"
                         type="text"
                         maxlength="255"
                         v-model="registerForm.email"
                         name="email"
                         autocomplete="off"
                         placeholder=""
                         @blur="clearError('email')"
                         :class="{ 'is-not-valid': errors.email }">
                  <span class="form__name">Почта</span>
                  <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
                </div>
              </label>
<!--              <label class="form__wrap">-->
<!--                <div class="form__inner-input">-->
<!--                  <input class="form__input"-->
<!--                         type="text"-->
<!--                         maxlength="10"-->
<!--                         @input="formatBirthdayInput"-->
<!--                         v-model="registerForm.birthday"-->
<!--                         autocomplete="off"-->
<!--                         @blur="clearError('birthday')"-->
<!--                         name="birthday"-->
<!--                         placeholder=""-->
<!--                         :class="{ 'is-not-valid': errors.birthday }"-->
<!--                         required>-->
<!--                  <span class="form__name">Дата рождения</span>-->
<!--                  <span v-if="errors.birthday" class="error-message">{{ errors.birthday }}</span>-->
<!--                </div>-->
<!--              </label>-->
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input class="form__input"
                         maxlength="255"
                         v-model="state.ref"
                         type="text"
                         name="promocode"
                         placeholder=""
                         autocomplete="off">
                  <span class="form__name">Реферальный код</span>
                </div>
              </label>
            </div>
            <div class="form__checkboxes">
              <label class="checkbox">
                <input type="checkbox" class="checkbox__real-input" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                <span class="checkbox__custom-input"></span>
                <span class="checkbox__text">Я согласен(на) на <a :href="agreementUrl" target="_blank">обработку моих персональных данных</a></span>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </label>
              <label class="checkbox">
                <input type="checkbox" class="checkbox__real-input" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                <span class="checkbox__custom-input"></span>
                <span class="checkbox__text">Согласен(на) <a :href="rulesUrl" @click="handleRulesButtonClick()" target="_blank">с правилами акции</a></span>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </label>
              <label class="checkbox">
                <input type="checkbox" class="checkbox__real-input" v-model="registerForm.agree_to_advertising">
                <span class="checkbox__custom-input"></span>
                <span class="checkbox__text">Я согласен(на) на получение персональных предложений от партнера</span>
              </label>
            </div>
            <div class="form__footer">
              <div class="error-message" v-if="responseError">{{ responseError }}</div>
              <button class="send-button form__btn btn"
                      :class="{'--loader-active': formSending}"
                      :disabled="state.promoStatus === 'end'">Регистрация</button>
              <div class="form__btn-wrapper">
                <span class="form__text-btn">Уже есть аккаунт?</span>
                <button type="button" class="send-button form__btn btn --border" @click="switchToLogin">Войти</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="modal-close">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <h5 class="modal-title">Спасибо за регистрацию.</h5>
          <p>Пароль для входа отправлен на email.
            <br>Проверьте, пожалуйста, папку спам.</p>
        </div>
      </div>
    </div>

    <!-- Popup успешной отправки вопроса -->
    <div v-if="showQuestionPopup" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopup"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopup" class="popup__close"></button>
        <div class="popup__title">Обратная связь</div>
        <div class="popup__content">
          <form @submit.prevent="submitQuestion" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-name">Имя</label>
                <input
                    type="text"
                    v-model="questionForm.name"
                    id="feedback-name"
                    name="name"
                    @input="removeSpacesName" />
                <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-email">Почта</label>
                <input
                    type="text"
                    v-model="questionForm.email"
                    id="feedback-email"
                    name="email"
                    :disabled="user && !!user.email" />
                <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-subject">Тема обращения</label>
                <select name="subject" id="feedback-subject" v-model="questionForm.subject">
                  <option value="" disabled selected>Выберите тему</option>
                  <option v-for="option in options" :key="option.id" :value="option.id">
                    {{ option.title }}
                  </option>
                </select>
                <span v-if="feedbackErrors.subject" class="error-message">{{ feedbackErrors.subject }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-message">Текст обращения</label>
                <textarea v-model="questionForm.text" id="feedback-message" name="text"></textarea>
                <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="files" class="attach-file">Прикрепить файл</label>
                <input
                    type="file"
                    id="files"
                    name="files"
                    multiple
                    @change="handleFileChange"
                    accept=".jpg, .jpeg, .png, .gif, .tiff, .webp, .pdf">
                <div class="attach-info">
                  <p>Разрешенные форматы файлов: .jpg, .jpeg, .png, .gif, .tiff, .webp, .pdf.
                    <br>Максимальный размер файла: 500 MB.
                    <br>Максимальное количество файлов: 10.</p>
                </div>


                <!-- Отображение превью файлов -->
                <div class="file-preview" v-if="filePreviews.length">
                  <div v-for="(file, index) in filePreviews" :key="index" class="file-preview-item">
                    <div v-if="file.type.startsWith('image')" class="file-preview-item__img">
                      <img :src="file.preview" :alt="file.name" />
                    </div>
                    <button @click="removeFile(index)" class="remove-file-btn"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="feedback-personality">
                  <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                  <span>Я согласен(на) на <a :href="agreementUrl" target="_blank" class="link">обработку моих персональных данных</a></span>
                </label>
                <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-main button-main--full">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showQuestionPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
      <div class="popup__window">
        <button class="modal-close" @click="closeQuestionPopupSuccess">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <h5 class="modal-title">Спасибо за обращение!</h5>
          <p class="modal-subtitle">Мы обязательно Вам ответим в течение 3 рабочих дней.</p>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button class="modal-close" @click="closeResetPasswordPopup">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <h5 class="modal-title">Восстановление пароля</h5>
          <form class="form form-grid" @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="form__block-inputns">
              <label class="form__wrap">
                <div class="form__inner-input">
                  <input type="text" id="reg-phone"
                         v-model="resetPasswordForm.email"
                         name="email"
                         placeholder=""
                         required
                         class="form__input"
                         :class="{ 'is-not-valid': resetPasswordErrors.email }">
                  <span class="form__name">Почта</span>
                  <span v-if="resetPasswordErrors.email" class="error-message">{{ resetPasswordErrors.email }}</span>
                </div>
              </label>
            </div>
            <div class="form__footer">
              <button class="send-button btn"
                      :class="{'--loader-active': formSending}">Отправить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button class="modal-close" @click="closeResetPasswordPopupSuccess">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <h5 class="modal-title">Восстановление пароля</h5>
          <p class="modal-subtitle">На почту {{ this.resetPasswordEmail }} отправлен новый пароль</p>
          <div class="form form-grid">
            <button class="send-button btn" @click="switchToLogin">Войти</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Popup статусов акции -->
    <div v-if="showPromoNotStarted" class="popup">
      <div class="popup__overlay" @click="closeShowPromoNotStarted"></div>
      <div class="popup__window">
        <button class="modal-close" @click="closeShowPromoNotStarted">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <div class="modal__points points text-center">
            <div class="modal-subtitle points__title">Акция еще не началась, но начнется {{ state.promoStart }}.<br>Следите за обновлениями! </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPromoEnd" class="popup">
      <div class="popup__overlay" @click="closeShowPromoEnd"></div>
      <div class="popup__window">
        <button class="modal-close" @click="closeShowPromoEnd">
          <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
        </button>
        <div class="modal-content">
          <div class="modal__points points text-center">
            <h5 class="modal-title points__title">Акция завершена.
              <br>Спасибо за участие!</h5>
            <div class="modal-subtitle">
              Вручение призов осуществляется до&nbsp;31.12.2025&nbsp;г.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute; bottom: 100%; overflow: hidden;">
      <div id="apm-widget"></div>
    </div>
  </div>
</template>

<script>
/* global ym */
/* global qrWidget */
import { state } from "@/store";
import {
  fetchFAQ,
  getToken,
  getUserInfo,
  removeToken,
  authorize,
  register,
  getMainSettings,
  sendFeedback,
  setAuthHeader,
  sendResetPassword,
  fetchTopics,
  getUserChecks, getFeedbackTopics
} from './api';
import metrikaMixin from "@/mixins/metrikaMixin";
import {useGlobalSetup} from "@/composables/useGlobalSetup";
import { sendAdRiverMetrik } from '@/utils/metrika';

export default {
  name: 'App',
  mixins: [metrikaMixin],
  setup() {
    useGlobalSetup();
    return {
      state,
    };
  },
  data() {
    return {
      formSending: false,
      resetPasswordEmail: null,
      isOpen: false,
      selectedText: 'Тема обращения',
      topics: [],
      scrollPercentages: [25, 50, 75, 100],
      hasReached: {
        scroll_to_footer: false,
        scroll_page_25: false,
        scroll_page_50: false,
        scroll_page_75: false,
        scroll_page_100: false,
        timer_15_seconds: false,
        timer_60_seconds: false,
      },
      timerStarted: false,
      checkInterval: null,
      checksTotal: 0,
      checkSoon: false,
      utmParams: {},
      clientId: null,
      isHeaderScrolled: false,
      scrollThreshold: 0,
      rootUrl: window.location.origin,
      userId: '',
      userUuid: '',
      isMobileNavOpened: false,
      faq: [],
      prizes: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      showPromoNotStarted: false,
      showPromoEnd: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false,
        subject: '',
        files: []
      },
      options: [],
      filePreviews: [],
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        promocode: '',
        birthday: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 18,
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупайте товары', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируйте чеки', component: 'RegisterSection', scrolled: false},
        { name: 'Участвуйте в викторине', component: 'QuizSection', scrolled: false },
        { name: 'Выигрывайте призы', component: 'PrizeSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  async mounted() {
    sendAdRiverMetrik("all-pages");
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    if (ref) {
      state.ref = ref;
    }
    await this.fetchTopics();
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keydown', this.handleKeydown);
    this.getClientId();
    this.getUTMParams();
    // this.fetchUsersCheck();
    document.addEventListener('click', this.handleDynamicButtonClick);
    if (!this.timerStarted) {
      this.startTimer(15, 'timer-15');
      this.startTimer60(30, 'timer-60');
      this.timerStarted = true;
    }
    this.fetchOptions();
    document.documentElement.style.setProperty('--text-accent-1-1', 'var(--desired-color)');
    window.addEventListener('colorChange', (event) => {
      this.color = event.detail;
    });
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
          this.userUuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    tgBotUrl() {
      return `${process.env.VUE_APP_TG_BOT_URL}`;
    },
    rulesUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/rules`;
    },
    policyUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/policy`;
    },
    agreementUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/agreement`;
    },
    eyeIcon() {
      return require('@/assets/img/icons/eye.svg');
    },
    eyeOffIcon() {
      return require('@/assets/img/icons/eye-off.svg');
    },
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    handleParticipateButton() {

      // eslint-disable-next-line no-undef
      const img = document.createElement("img");
      img.src = "https://bu--s75.sync.t2.ru";
      img.width = 0;
      img.height = 0;
      img.alt = "";
      img.style.border = "0";
      img.style.position = "absolute";
      img.style.visibility = "hidden";
      document.body.appendChild(img);
      img.onload = img.onerror = () => {
        setTimeout(() => img.remove(), 3000); // Удаляем через 5 секунд
      };
    },
    scrollToTop() {
      // Плавно прокручиваем страницу вверх
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    async fetchTopics() {
      try {
        this.topics = await getFeedbackTopics();
      } catch (error) {
        console.error('Error loading topics:', error);
      }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$nextTick(() => {
        if (this.$refs.dropDownBtn && this.$refs.dropDownList) {
          this.$refs.dropDownList.style.top = `${this.$refs.dropDownBtn.offsetHeight + 5}px`;
        }
      });
    },
    selectItem(item) {
      this.selectedText = item.title;
      this.questionForm.subject = item.id;
      this.isOpen = false;
    },
    handleClickOutside(event) {
      // Проверяем, что this.$refs.dropdown существует
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    handleKeydown(event) {
      if (event.key === 'Tab' || event.key === 'Escape') {
        this.isOpen = false;
      }
    },
    getClientId() {
      if (typeof ym !== 'undefined') {
        ym(99695781, 'getClientID', (clientId) => {
          this.clientId = clientId;
          this.getUTMParams();
        });
      } else {
        console.error('Yandex.Metrica не доступна');
      }
    },
    // executeAdriverEvent(page) {
    //   const sidMapping = {
    //     faq: 'click_faq',
    //     prizes: 'click_prizes',
    //     winners: 'click_winners',
    //     close_registration: 'abandoned_user_registration_close_form',
    //     close_load: 'abandoned_check_registration_close_form',
    //     click_greenfield: 'click_greenfield',
    //     click_jardin: 'click_jardin',
    //     click_magnit: 'click_magnit',
    //     click_home: 'click_main',
    //     upload_check_open: 'click_register_check',
    //     click_how_use: 'click_how_participate',
    //     click_account: 'click_lk',
    //     registration_success: 'successful_user_registration',
    //     check_upload_success: 'successful_check_registration',
    //     timer_15: 'timer_15'
    //   };
    //
    //   if (!sidMapping[page]) return;
    //
    //   const sid = 229236; // ваш статический sid
    //   const bt = 62; // ваш статический bt
    //   const sz = sidMapping[page];
    //
    //   // Вызов Adriver-кода
    //   (function (e, n) {
    //     function o(e, n, o) {
    //       n = n || "&";
    //       o = o || "=";
    //       var d = [];
    //       for (var r in e)
    //         if (Object.prototype.hasOwnProperty.call(e, r)) {
    //           d.push(r + o + encodeURIComponent(e[r]));
    //         }
    //       return d.join(n);
    //     }
    //     function d(e, n) {
    //       var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
    //       return o ? decodeURIComponent(o[2]) : null;
    //     }
    //     var r,
    //         t,
    //         i,
    //         c,
    //         u;
    //     (r = e),
    //         (t = n),
    //         (i = document.domain),
    //         (c = { tail256: document.referrer || "unknown" }),
    //     void 0 !==
    //     (u = (function (e) {
    //       var n = {};
    //       if (e) {
    //         var o = e.split("&");
    //         for (var d in o)
    //           if (Object.prototype.hasOwnProperty.call(o, d)) {
    //             var r = o[d].split("=");
    //             void 0 !== r[0] &&
    //             void 0 !== r[1] &&
    //             (n[r[0]] = decodeURIComponent(r[1]));
    //           }
    //       }
    //       return n;
    //     })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
    //     null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")),
    //     t &&
    //     t.id &&
    //     null !== d(document, t.id) &&
    //     (r.suid = i + "_" + encodeURIComponent(d(document, t.id))),
    //         t && t.gid1
    //             ? (r.gid1 = t.gid1)
    //             : null !== d(document, "_ga") &&
    //             (r.gid1 = encodeURIComponent(d(document, "_ga"))),
    //         t && t.yid1
    //             ? (r.yid1 = t.yid1)
    //             : null !== d(document, "_ym_uid") &&
    //             (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))),
    //         (r.loc = encodeURIComponent(window.location.href)),
    //     r.custom && (r.custom = o(r.custom, ";")),
    //         (function (e, n) {
    //           !(function (e) {
    //             if (
    //                 ((e = e.split("![rnd]").join(~~(1e6 * Math.random()))),
    //                 document.createElement && document.body)
    //             ) {
    //               var n = document.createElement("img");
    //               (n.style.position = "absolute"),
    //                   (n.style.display = "none"),
    //                   (n.style.width = n.style.height = "0px"),
    //                   n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
    //                   (n.src = e),
    //                   document.body.appendChild(n);
    //             } else {
    //               var o = new Image();
    //               o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
    //                   (o.src = e);
    //             }
    //           })(
    //               "https://ad.adriver.ru/cgi-bin/rle.cgi?" +
    //               e +
    //               "&rnd=![rnd]" +
    //               (n ? "&" + n : "")
    //           );
    //         })(o(r), o(c));
    //   })({ sid: sid, bt: bt, sz: sz }, { id: "", gid1: "", yid1: "" });
    // },
    // executeDSPCounterEvent(siteArea) {
    //   const sid = 229303; // ваш статический sid
    //   const siteAreaMapping = {
    //     '30_sec': 'TJCuegy2',
    //     'login_success': 'qh1jImpT',
    //     'check_upload_success': 'hAf9oUO0',
    //     'buttons_click': 'bG46a4ie',
    //     'faq_click': 'USZY4vWn',
    //   };
    //
    //   const siteAreaId = siteAreaMapping[siteArea] || 'USZY4vWn'; // если не нашли, использовать дефолтный
    //   // Проверка и отложенный вызов если DSPCounter еще не загружен
    //   const checkDSPCounter = setInterval(() => {
    //     if (window.DSPCounter) {
    //       clearInterval(checkDSPCounter);
    //       window.DSPCounter('send', {
    //         'sid': sid,
    //         'site_area': siteAreaId,
    //         'user_id': '',
    //         'lead_id': '',
    //         'order_sum': ''
    //       });
    //     }
    //   }, 100); // проверяем каждые 100ms
    // },
    handleDynamicButtonClick(event) {
      if (event.target.id === 'apm-close-widget-button') {
        state.isUploadOpened = false;
        this.$sendMetrik('reachGoal', 'close_load');
        // this.executeAdriverEvent('close_load');
        // Логика обработки клика
      }
    },
    startTimer(seconds, eventKey) {
      setTimeout(() => {
        if (!this.hasReached[eventKey]) {
          this.$sendMetrik('reachGoal', eventKey);
          if (eventKey == 'timer-15') {
            this.hasReached['timer_15_seconds'] = true;
          } else if (eventKey == 'timer-60') {
            this.hasReached['timer_60_seconds'] = true;
          } else {
            this.hasReached[eventKey] = true;
          }
          // this.executeAdriverEvent('timer_15');
        }
      }, seconds * 1000);
    },
    startTimer60(seconds, eventKey) {
      setTimeout(() => {
        if (!this.hasReached[eventKey]) {
          this.$sendMetrik('reachGoal', eventKey);
          this.hasReached[eventKey] = true;
          // this.executeDSPCounterEvent('30_sec');
        }
      }, seconds * 1000);
    },
    startCheckingForNewChecks() {
      // Если уже идет проверка, не запускаем ещё раз
      if (this.checkInterval) return;

      this.checkInterval = setInterval(async () => {
        try {
          const checksData = await getUserChecks(this.checkOffset, this.checkLimit);
          if (checksData.count && checksData.count !== state.checksTotal) {
            // Обновляем данные, если количество чеков изменилось
            state.checksTotal = checksData.count;
            state.checks.results = checksData.results;

            // Останавливаем таймер
            this.stopCheckingForNewChecks();

            // Очищаем флаг ожидания
            state.checkSoon = false;
          }
        } catch (error) {
          console.error('Error checking for new checks:', error);
        }
      }, 5000); // Проверяем каждые 5 секунд
    },
    stopCheckingForNewChecks() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
    getUTMParams() {
      const params = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content'),
        click_id: params.get('click_id')
      };

      // Фильтрация null значений
      this.utmParams = Object.fromEntries(
          Object.entries(utmParams).filter(([, value]) => value !== null)
      );

      // Добавляем clientId в utmParams после его получения
      if (this.clientId) {
        this.utmParams.client_id = this.clientId;
      }
    },
    async fetchOptions() {
      try {
        this.options = await fetchTopics(10, 0);
      } catch (error) {
        console.error("Ошибка загрузки тем:", error);
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      // Фильтрация по разрешённым типам файлов и размеру
      const validFiles = files.filter(file => {
        const isValidType = /\.(jpg|jpeg|png|gif|tiff|webp|pdf)$/i.test(file.name);
        const isValidSize = file.size <= 500 * 1024 * 1024; // 500 MB
        return isValidType && isValidSize;
      });

      // Ограничение на 10 файлов
      if (validFiles.length + this.filePreviews.length > 10) {
        alert("Нельзя прикрепить больше 10 файлов.");
        return;
      }

      // Добавляем файлы в состояние и показываем их превью
      validFiles.forEach(file => {
        const preview = {
          file,
          name: file.name,
          type: file.type,
          preview: URL.createObjectURL(file) // для изображений
        };
        this.filePreviews.push(preview);
        this.questionForm.files.push(file);
      });
    },

    // Удаление файла
    removeFile(index) {
      // Удаляем файл из массива файлов
      this.filePreviews.splice(index, 1);
      this.questionForm.files.splice(index, 1);
    },
    handleRulesButtonClick() {
      this.$sendMetrik('reachGoal', 'rules');
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    async logout() {
      state.checkSoon = false;
      state.promoStatus = '';
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.user = {
        address: {
          apartment: null,
          city: null,
          email: null,
          fio: null,
          house: null,
          phone_number: null,
          street: null,
          zip_code: null,
        },
        bonus_checks_count: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        birthday: null,
        id: null,
        uuid: null,
        is_possible_to_upload_receipt: null,
        address_is_filled: null,
        promocode: null,
        referral_id: null,
        referral_link: null,
        balance: null,
        privilege_level: null,
        need_points: null
      };
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      window.location.href = '/';
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$sendMetrik('reachGoal', 'click_account');
      // this.executeAdriverEvent('click_account');
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage(page) {
      if (page == 'link') {
        this.$sendMetrik('reachGoal', 'click_home');
        // this.executeAdriverEvent('click_home');
      }
      this.$router.push('/');
    },
    logoLinkClick(page) {
      if (page == 'magnit') {
        this.$sendMetrik('reachGoal', 'click_magnit');
      } else if (page == 'jardin') {
        this.$sendMetrik('reachGoal', 'click_jardin');
        // this.executeAdriverEvent('click_jardin');
      } else if (page == 'green') {
        this.$sendMetrik('reachGoal', 'click_greenfield');
        // this.executeAdriverEvent('click_greenfield');
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    formatBirthdayInput(event) {
      let value = event.target.value.replace(/\D/g, ''); // Оставляем только цифры

      // Ограничиваем длину до 8 символов
      if (value.length > 8) {
        value = value.slice(0, 8);
      }

      // Форматируем строку в DD-MM-YYYY
      let formattedValue = value;

      if (value.length >= 2) {
        formattedValue = value.slice(0, 2) + '-' + value.slice(2); // Добавляем дефис после двух первых цифр
      }
      if (value.length >= 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5); // Добавляем дефис после пяти символов
      }

      // Удаляем дефис, если длина строки меньше 5 или 2
      if (formattedValue.length < 5) {
        formattedValue = formattedValue.replace('-', '');
      }

      if (formattedValue.length < 2) {
        formattedValue = formattedValue.replace('-', '');
      }

      // Присваиваем форматированное значение в форму
      this.registerForm.birthday = formattedValue;
    },
    convertBirthdayFormat(date) {
      if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
      }
      return date;
    },
    triggerQrScan() {
      // this.executeDSPCounterEvent('buttons_click');
      if (state.promoStatus == 'start') {
        const qrElement = document.getElementById('apm-scan-qr');
        if (qrElement) {
          // this.$sendMetrik('reachGoal', 'upload_check_open');
          // this.executeAdriverEvent('upload_check_open');
          state.isUploadOpened = true;
          qrElement.click();
        }
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      } else if (state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoEnd = true;
      }
    },
    initQrWidget() {
      if (!document.getElementById('apm-scan-qr')) {
        qrWidget.init('apm-widget', {
          api: 'https://api.apmcheck.ru',
          apiKey: 'b6bfe0bb-cdfb-4d32-900e-d9095711da65',
          userUuid: this.userUuid,
          callbacks: {
            onReceiptSentSuccess: () => {
              this.$sendMetrik('reachGoal', 'load_success');
              this.$sendAdRiverMetrik('receipt-sent');
              // this.executeDSPCounterEvent('check_upload_success');
              // this.executeAdriverEvent('check_upload_success');
              state.checkSoon = true;
              this.startCheckingForNewChecks();
            },
            onReceiptSentError: () => {
              state.isUploadOpened = false;
            }
          }
        });
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.addBodyClass();
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.removeBodyClass();
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      // this.executeDSPCounterEvent('buttons_click');
      this.addBodyClass();
      this.showQuestionPopup = true;
      this.questionForm.name = state.user.first_name;
      this.questionForm.email = state.user.email;
    },
    closeQuestionPopup() {
      this.removeBodyClass();
      this.showQuestionPopup = false;
      this.resetForm();
      // Очищаем файлы и их превью
      this.filePreviews = [];
      this.questionForm.files = [];
    },
    openQuestionPopupSuccess() {
      this.addBodyClass();
      this.showQuestionPopupSuccess = true;
      this.resetForm();
    },
    closeQuestionPopupSuccess() {
      this.removeBodyClass();
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
      this.addBodyClass();
    },
    closeResetPasswordPopup() {
      this.removeBodyClass();
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.addBodyClass();
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.resetPasswordEmail = null;
      this.removeBodyClass();
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    closeShowPromoNotStarted() {
      this.removeBodyClass();
      this.showPromoNotStarted = false;
    },
    closeShowPromoEnd() {
      this.removeBodyClass();
      this.showPromoEnd = false;
    },
    async submitQuestion() {
      try {
        this.feedbackErrors = {};

        // Валидация формы
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите почту';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите текст обращения';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!this.questionForm.subject || this.questionForm.subject === 'Выберите тему') {
          this.feedbackErrors.subject = 'Выберите тему';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return;
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text,
          subject: this.questionForm.subject,
          files: this.questionForm.files // отправляем файлы
        });

        this.closeQuestionPopup();
        this.openQuestionPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        this.formSending = true;
        const { email } = this.resetPasswordForm;
        this.resetPasswordErrors = {};

        // Валидация длины телефонного номера
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.resetPasswordErrors.email = 'Введите корректный email';
        }

        // Отправка данных формы через API
        await sendResetPassword({ email: email });

        // Успешная отправка, здесь можно закрыть попап
        this.resetPasswordEmail = email;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
        this.formSending = false;
      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.filePreviews = [];
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        subject: '',
        agree_personal_data: false,
        files: []
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        birthday: '',
        promocode: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;

      // // Если футер в зоне видимости
      // const footer = document.querySelector('footer');
      // if (footer) {
      //   const footerVisible = footer.getBoundingClientRect().top < window.innerHeight;
      //   if (footerVisible && !this.hasReached.scroll_to_footer) {
      //     this.$sendMetrik('reachGoal', 'scroll_to_footer');
      //     this.hasReached.scroll_to_footer = true;
      //   }
      // }

      // Отслеживание процентов прокрутки
      const scrollPercentage = (scrollPosition / pageHeight) * 100;
      this.scrollPercentages.forEach((percentage) => {
        const eventKey = `scroll-${percentage}`;
        if (scrollPercentage >= percentage && !this.hasReached[eventKey]) {
          this.$sendMetrik('reachGoal', eventKey);
          this.hasReached[eventKey] = true;
          if(percentage === 50){
            this.$sendAdRiverMetrik("scroll50")
          }
          if(percentage === 75){
            this.$sendAdRiverMetrik("scroll75")
          }
        }
      });


      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;

      const sections = document.querySelectorAll('.register-section');

      let currentSectionIndex = -1;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.sections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.sections.length; i++) {
          this.sections[i].scrolled = false;
        }
      } else {
        this.activeSection = 0;
        this.sections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeSectionIndex = index;
    },
    isActive(index) {
      return this.activeSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeSectionIndex;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    formatDateRange(start_date, end_date) {
      const start = new Date(start_date);
      const end = new Date(end_date);

      const format = (date) =>
          String(date.getUTCDate()).padStart(2, "0") + "." +
          String(date.getUTCMonth() + 1).padStart(2, "0") + "." +
          date.getUTCFullYear();

      return `${format(start)} – ${format(end)}`;
    },
    formatDateStart(start_date) {
      const start = new Date(start_date);

      return String(start.getUTCDate()).padStart(2, "0") + "." +
          String(start.getUTCMonth() + 1).padStart(2, "0") + "." +
          start.getUTCFullYear();
    },
    formatDateEnd(end_date) {
      const end = new Date(end_date);

      return String(end.getUTCDate()).padStart(2, "0") + "." +
          String(end.getUTCMonth() + 1).padStart(2, "0") + "." +
          end.getUTCFullYear();
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();
        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
          this.promoDates = start_date + ' - ' + end_date;
          state.promoStatus = 'not-started'
          state.promoStart = this.formatDateStart(start_date);
        } else if (now >= startDate && now < endDate) {
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
          this.promoDates = start_date + ' - ' + end_date;
          state.promoStatus = 'start'
        } else {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
          this.promoDates = startDate + ' - ' + end_date;
          state.promoStatus = 'end'
          state.promoEnd = this.formatDateEnd(end_date);
        }
        this.promoDates = this.formatDateRange(start_date, end_date);

        const promoShown = sessionStorage.getItem('promoShown');
        if (!promoShown && state.promoStatus === 'end') {
          this.showPromoEnd = true;
          sessionStorage.setItem('promoShown', 'true');
        }

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    openLoginPopup() {
      if (state.promoStatus == 'start' || state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showLoginPopup = true;
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      }
    },
    closeLoginPopup() {
      this.removeBodyClass();
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.registerForm.promocode = state.ref;
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
      this.addBodyClass();

      // eslint-disable-next-line no-undef
      const img = document.createElement("img");
      img.src = "https://bu--s76.sync.t2.ru";
      img.width = 0;
      img.height = 0;
      img.alt = "";
      img.style.border = "0";
      img.style.position = "absolute";
      img.style.visibility = "hidden";
      document.body.appendChild(img);
      img.onload = img.onerror = () => {
        setTimeout(() => img.remove(), 3000); // Удаляем через 5 секунд
      };
    },
    closeRegisterPopup() {
      this.$sendMetrik('reachGoal', 'close_registration');
      // this.executeAdriverEvent('close_registration');
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    closeRegisterFinishPopup() {
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      // this.executeDSPCounterEvent('login_success');
      this.$sendMetrik('reachGoal', 'registration_success');
      this.$sendAdRiverMetrik('sign-up');
      // this.executeAdriverEvent('registration_success');
      this.addBodyClass();
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.removeBodyClass();
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        this.formSending = true;
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          this.formSending = false;
          return;
        }

        await authorize(formattedPhoneNumber, this.password);
        this.$sendAdRiverMetrik('log-in');
        this.$sendMetrik('reachGoal', 'log-in');
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.userUuid = userInfo.uuid;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.initQrWidget();
              // this.executeDSPCounterEvent('login_success');
              setTimeout(async () => {
                this.$router.push('/private');
              }, 400); // 500 мс задержка
              this.removeBodyClass();
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
          this.formSending = false;
        }, 900); // 500 мс задержка

      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        this.formSending = true;
        const { first_name, last_name, phone_number, email, birthday, promocode, agree_personal_data, agree_rules, agree_to_advertising } = this.registerForm;

        // Форматируем дату только перед отправкой, но не меняем значение в поле
        const formattedBirthday = this.convertBirthdayFormat(birthday);

        // Форматируем телефон
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверки
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем
        if (Object.keys(this.errors).length > 0) {
          this.formSending = false;
          return;
        }

        const utmParamsList = this.utmParams;

        if (state.ref !== '') {
          this.registerForm.promocode = state.ref;
        }

        // Отправляем запрос с форматированной датой
        await register(first_name, last_name, formattedPhoneNumber, email, formattedBirthday, promocode, JSON.stringify(utmParamsList), agree_to_advertising);

        this.closeRegisterFinishPopup();
        this.openRegisterPopupSuccess();
        this.formSending = true;
      } catch (error) {
        this.formSending = true;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.addBodyClass();
      this.showResetPasswordSuccess = false;
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
      this.responseError = '';
    }
  },
  beforeUnmount() {
    // Очищаем таймер при разрушении компонента
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeydown);
    this.stopCheckingForNewChecks();
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleDynamicButtonClick);
  },
};
</script>


<style lang="scss">
  @import '@/assets/scss/App.scss';
</style>