import { sendMetrik, sendAdRiverMetrik } from '@/utils/metrika'; // Путь к вашей функции

export default {
    methods: {
        $sendMetrik(event, goal) {
            sendMetrik(event, goal);
        },
        $sendAdRiverMetrik(sz){
            sendAdRiverMetrik(sz);
        }
    }
};