// store.js
import { reactive } from "vue";

export const state = reactive({
    isUploadOpened: false,
    checkSoon: false,
    promoStatus: '',
    checksTotal: 0,
    promoStart: null,
    promoEnd: null,
    ref: null,
    checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    user: {
        address: {
            apartment: null,
            city: null,
            email: null,
            fio: null,
            house: null,
            phone_number: null,
            street: null,
            zip_code: null,
        },
        bonus_checks_count: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        id: null,
        uuid: null,
        birthday: null,
        is_possible_to_upload_receipt: null,
        address_is_filled: null,
        promocode: null,
        referral_id: null,
        referral_link: null,
        balance: null,
        privilege_level: null,
        need_points: null
    }
});