import { createApp } from 'vue';
import App from './App.vue';
import { createMetaManager } from 'vue-meta';  // Используем createMetaManager для Vue 3
import mask from './directives/mask';
import router from './router/index';

const app = createApp(App);

app.use(router);
app.use(createMetaManager());  // Используем createMetaManager вместо app.use(VueMeta)

app.directive('mask', mask);
app.mount('#app');
