<template>
  <div class="privilege__swiper">
    <Swiper
        :modules="[Pagination]"
        :slides-per-view="3"
        :space-between="30"
        :pagination="{ el: '.privilege__pagination', clickable: true }"
        :breakpoints="breakpoints"
    >
      <SwiperSlide v-for="(level, index) in privilegeLevels" :key="index">
        <div class="privilege-slide">
          <div class="privilege-slide__header">
            <span>{{ level.title }}</span>
            <span>{{ level.description }}</span>
          </div>
          <div class="privilege-slide__card">
            <img
                v-if="index === 0"
                width="476"
                height="300"
                src="@/assets/img/privilege/card_1.webp"
                srcset="@/assets/img/privilege/card_1@2x.webp 2x"
            />
            <img
                v-if="index === 1"
                width="476"
                height="300"
                src="@/assets/img/privilege/card_2.webp"
                srcset="@/assets/img/privilege/card_2@2x.webp 2x"
            />
            <img
                v-if="index === 2"
                width="476"
                height="300"
                src="@/assets/img/privilege/card_3.webp"
                srcset="@/assets/img/privilege/card_3@2x.webp 2x"
            />
          </div>
          <ul class="privilege-slide__list">
            <li
                v-for="(item, i) in level.benefits"
                :key="i"
                class="privilege-slide__item"
                :class="{ plus: item.type === 'plus', minus: item.type === 'minus' }"
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="privilege__pagination pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
  components: { Swiper, SwiperSlide },
  setup() {
    const privilegeLevels = [
      {
        title: 'SILVER',
        description: 'Всем участникам при регистрации',
        image: '@/assets/img/privilege/card_1.webp',
        benefits: [
          { text: 'Доступ к гарантированным подаркам', type: 'plus' },
          { text: 'Доступ к накопительным физическим подаркам', type: 'plus' },
        ],
      },
      {
        title: 'GOLD',
        description: 'При накоплении 500 баллов',
        image: 'card_2.webp',
        benefits: [
          { text: 'Доступ к гарантированным подаркам', type: 'plus' },
          { text: 'Доступ к накопительным физическим подаркам', type: 'plus' },
          { text: 'Доступ к накопительным электронным подаркам', type: 'plus' },
          { text: 'Участвует в розыгрыше ежемесячных призов', type: 'plus' },
        ],
      },
      {
        title: 'PLATINUM',
        description: 'При накоплении 1000 баллов',
        image: 'card_3.webp',
        benefits: [
          { text: 'Доступ к гарантированным подаркам', type: 'plus' },
          { text: 'Доступ к накопительным физическим подаркам', type: 'plus' },
          { text: 'Доступ к накопительным электронным подаркам', type: 'plus' },
          { text: 'Участвует в розыгрыше ежемесячных призов', type: 'plus' },
          { text: 'Участвует в розыгрыше главного приза', type: 'plus' },
        ],
      },
    ];

    const breakpoints = {
      320: { centeredSlides: true, slidesPerView: 1.3, spaceBetween: 10 },
      393: { centeredSlides: true, slidesPerView: 1.3, spaceBetween: 24 },
      481: { slidesPerView: 1.7, spaceBetween: 24 },
      769: { slidesPerView: 2.3, spaceBetween: 24 },
      1024: { slidesPerView: 2.5, spaceBetween: 31 },
      1025: { slidesPerView: 3, spaceBetween: 31 },
    };

    return {
      privilegeLevels,
      breakpoints,
      Pagination,
    };
  },
};
</script>

<style scoped>
</style>
