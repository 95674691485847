<template>

  <div class="wrapper">
    <div id='overlay' :class="{ active: isMenuOpen }" @click="closeMenu"></div>
    <div class="mobile-menu" :class="{ active: isMenuOpen }" @click="closeMenu">
      <div class="header__content">
        <nav class="nav">
          <!--            @click="SendMetrick('how-register')"-->
          <a href="/#guaranteed" class="nav__link" @click="SendMetrick('header-prizes')">Призы</a>
          <a href="/#winners" class="nav__link" @click="SendMetrick('header-winners')">Победители</a>
          <a href="/#faq" class="nav__link" @click="SendMetrick('header-faq')">Вопрос-ответ</a>
        </nav>
        <div class="header__controls">
          <a :href="rulesUrl" class="header__rules" @click="handleRulesButtonClick()" target="_blank">
            <span>Правила акции</span>
            <svg width='20' height='20'><use href='@/assets/img/icons/icons.svg#download'></use></svg>
          </a>
          <button v-if="!user" @click="openLoginPopup" class="header__btn">
            Личный кабинет
          </button>
          <template v-else>
            <button class="header__btn" @click="redirectToPrivatePage">
              <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
            </button>
            <button class="header__btn" @click="logout">
              <svg width='24' height='24'><use href='@/assets/img/icons/icons.svg?v_2#log-out'></use></svg>
            </button>
          </template>
        </div>
      </div>
    </div>
    <header class="header" id="header">
      <div class="container">
        <div class="header__container">
          <a @click="redirectToHomePage('link')" href="#" class="logo">
            <img loading='lazy' width="216" height="102" src="@/assets/img/logo.webp" srcset="@/assets/img/logo@2x.webp 2x" alt="logotip">
          </a>
          <div class="header__content">
            <nav class="nav">
              <a href="/#guaranteed" class="nav__link" @click="SendMetrick('header-prizes')">Призы</a>
              <a href="/#winners" class="nav__link" @click="SendMetrick('header-winners')">Победители</a>
              <a href="/#faq" class="nav__link" @click="SendMetrick('header-faq')">Вопрос-ответ</a>
            </nav>
            <div class="header__controls">
              <a :href="rulesUrl" class="header__rules" @click="handleRulesButtonClick()" target="_blank">
                <span>Правила акции</span>
                <svg width='20' height='20'><use href='@/assets/img/icons/icons.svg#download'></use></svg>
              </a>
              <button v-if="!user" @click="openLoginPopup" class="header__btn">
                Личный кабинет
              </button>
              <template v-else>
                <button class="header__btn" @click="redirectToPrivatePage">
                  <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
                </button>
                <button class="header__btn" @click="logout">
                  <svg width='24' height='24'><use href='@/assets/img/icons/icons.svg?v_2#log-out'></use></svg>
                </button>
              </template>
            </div>
          </div>
          <div class="nav-icon" :class="{ active: isMenuOpen }" @click="toggleMenu"><span></span></div>
        </div>
      </div>
    </header>
    <main class="main">
      <section class="main-screen-404" id="mainScreen" style="padding-top: 143px;">
        <div class="container">
          <div class="main-screen-404__container">
            <h1 class="main-screen-404__title title2">Страница не найдена</h1>
            <p>К сожалению такой страницы не существует.</p>
            <a @click="redirectToHomePage('link')" href="#" class="main-screen-404__btn btn">Назад</a>
            <span class="main-screen-404__text text-transperent">404</span>
          </div>
        </div>
      </section>
    </main>
  </div>
  <!-- Popup для входа -->
  <div v-if="showLoginPopup" class="popup">
    <div class="popup__overlay" @click="closeLoginPopup"></div>
    <div class="popup__window">
      <button @click="closeLoginPopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Авторизация</h5>
        <form class="form form-grid" @submit.prevent="login" autocomplete="off">
          <div class="form__block-inputns">
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input" type="text" name="email" v-model="email" placeholder="" required>
                <span class="form__name">Почта</span>
                <span v-if="loginFormErrors.email" class="error-message">{{ loginFormErrors.email }}</span>
              </div>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                    <span class="form__icon-eye" @click="togglePasswordVisibility">
                      <img
                          :src="passwordVisible ? eyeOffIcon : eyeIcon"
                          width="24"
                          height="24"
                      />
                    </span>
                <input class="form__input" :type="passwordVisible ? 'text' : 'password'" maxlength="255" name="password" v-model="password"  placeholder="" required>
                <span class="form__name">Пароль</span>
              </div>
              <button class="form__text-btn --forget" type="button" @click="openResetPasswordPopup">Забыли пароль?</button>
            </label>
          </div>
          <div class="form__footer">
            <div class="error-message" v-if="responseError">{{ responseError }}</div>
            <button class="send-button form__btn btn"
                    :class="{'--loader-active': formSending}"
                    type="submit">Войти</button>
            <div class="form__btn-wrapper">
              <span class="form__text-btn">Нет аккаунта?</span>
              <button class="send-button form__btn btn" @click="openRegisterPopup" :disabled="state.promoStatus === 'end'" type="button">Регистрация</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

  <!-- Popup для регистрации -->
  <div v-if="showRegisterPopup" class="popup">
    <div class="popup__overlay" @click="closeRegisterPopup"></div>
    <div class="popup__window">
      <button @click="closeRegisterPopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Регистрация</h5>
        <form class="form form-grid" @submit.prevent="register" autocomplete="off">
          <div class="form__block-inputns">
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input"
                       maxlength="255"
                       v-model="registerForm.first_name"
                       @input="removeSpacesFirstName"
                       type="text"
                       name="first_name"
                       placeholder=""
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-not-valid': errors.first_name }"
                       required>
                <span class="form__name">Имя</span>
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input"
                       maxlength="255"
                       v-model="registerForm.last_name"
                       @input="removeSpacesLastName"
                       name="last_name"
                       type="text"
                       placeholder=""
                       required>
                <span class="form__name">Фамилия</span>
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input"
                       type="text"
                       placeholder=""
                       required
                       maxlength="18"
                       v-model="registerForm.phone_number"
                       name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-not-valid': errors.phone_number }" v-mask>
                <span class="form__name">Номер телефона</span>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input"
                       type="text"
                       maxlength="255"
                       v-model="registerForm.email"
                       name="email"
                       autocomplete="off"
                       placeholder=""
                       @blur="clearError('email')"
                       :class="{ 'is-not-valid': errors.email }">
                <span class="form__name">Почта</span>
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </label>
            <!--                <label class="form__wrap">-->
            <!--                  <div class="form__inner-input">-->
            <!--                    <input class="form__input"-->
            <!--                           type="text"-->
            <!--                           maxlength="10"-->
            <!--                           @input="formatBirthdayInput"-->
            <!--                           v-model="registerForm.birthday"-->
            <!--                           autocomplete="off"-->
            <!--                           @blur="clearError('birthday')"-->
            <!--                           name="birthday"-->
            <!--                           placeholder=""-->
            <!--                           :class="{ 'is-not-valid': errors.birthday }"-->
            <!--                           required>-->
            <!--                    <span class="form__name">Дата рождения</span>-->
            <!--                    <span v-if="errors.birthday" class="error-message">{{ errors.birthday }}</span>-->
            <!--                  </div>-->
            <!--                </label>-->
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input"
                       maxlength="10"
                       v-model="state.ref"
                       type="text"
                       name="promocode"
                       placeholder=""
                       autocomplete="off">
                <span class="form__name">Реферальный код</span>
              </div>
            </label>
          </div>
          <div class="form__checkboxes">
            <label class="checkbox">
              <input type="checkbox" class="checkbox__real-input" v-model="registerForm.agree_to_advertising">
              <span class="checkbox__custom-input"></span>
              <span class="checkbox__text">Я согласен(на) на получение персональных предложений от партнера</span>
            </label>
            <label class="checkbox">
              <input type="checkbox" class="checkbox__real-input" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
              <span class="checkbox__custom-input"></span>
              <span class="checkbox__text">Я согласен(на) на <a :href="agreementUrl" target="_blank">обработку моих персональных данных</a></span>
            </label>
            <label class="checkbox">
              <input type="checkbox" class="checkbox__real-input" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
              <span class="checkbox__custom-input"></span>
              <span class="checkbox__text">Согласен(на) <a :href="rulesUrl" @click="handleRulesButtonClick()" target="_blank">с правилами акции</a></span>
            </label>
            <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
            <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
          </div>
          <div class="form__footer">
            <div class="error-message" v-if="responseError">{{ responseError }}</div>
            <button class="send-button form__btn btn"
                    :class="{'--loader-active': formSending}"
                    :disabled="state.promoStatus === 'end'">Регистрация</button>
            <div class="form__btn-wrapper">
              <span class="form__text-btn">Уже есть аккаунт?</span>
              <button type="button" class="send-button form__btn btn --border" @click="switchToLogin">Войти</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showRegisterPopupSuccess" class="popup">
    <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
    <div class="popup__window">
      <button @click="closeRegisterPopupSuccess" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Спасибо за регистрацию.</h5>
        <p>Пароль для входа отправлен на email.
          <br>Проверьте, пожалуйста, папку спам.</p>
      </div>
    </div>
  </div>

  <!-- Popup успешной отправки вопроса -->
  <div v-if="showQuestionPopupSuccess" class="popup">
    <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeQuestionPopupSuccess">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Спасибо за обращение!</h5>
        <p class="modal-subtitle">Мы обязательно Вам ответим в течение 3 рабочих дней.</p>
      </div>
    </div>
  </div>

  <!-- Popup для восстановления пароля -->
  <div v-if="showResetPasswordPopup" class="popup">
    <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeResetPasswordPopup">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Восстановление пароля</h5>
        <form class="form form-grid" @submit.prevent="submitResetPassword" autocomplete="off">
          <div class="form__block-inputns">
            <label class="form__wrap">
              <div class="form__inner-input">
                <input type="text" id="reg-phone"
                       v-model="resetPasswordForm.email"
                       name="email"
                       placeholder=""
                       required
                       class="form__input"
                       :class="{ 'is-not-valid': resetPasswordErrors.email }">
                <span class="form__name">Почта</span>
                <span v-if="resetPasswordErrors.email" class="error-message">{{ resetPasswordErrors.email }}</span>
              </div>
            </label>
          </div>
          <div class="form__footer">
            <div class="error-message error-message--center" v-if="responseError">{{ responseError }}</div>
            <button class="send-button btn"
                    :class="{'--loader-active': formSending}">Отправить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showResetPasswordSuccess" class="popup">
    <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeResetPasswordPopupSuccess">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Восстановление пароля</h5>
        <p class="modal-subtitle">На почту {{ this.resetPasswordEmail }} отправлен новый пароль</p>
        <div class="form form-grid">
          <button class="send-button btn" @click="switchToLogin">Войти</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showBalanceInfo" class="popup">
    <div class="popup__overlay" @click="closeBalanceInfoPopup"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeBalanceInfoPopup">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__points points text-center">
          <h5 class="modal-title points__title">Что такое баллы?</h5>
          <div class="points__content">
            <p>Баллы, начисляемые Участнику за покупку Товара и регистрацию Чека. Баллы начисляются из расчета: каждые 10 рублей = 1 балл.</p>
          </div>
          <button class="btn --border" @click="goBalanceClick">
            Заработать баллы
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup статусов акции -->
  <div v-if="showPromoNotStarted" class="popup">
    <div class="popup__overlay" @click="closeShowPromoNotStarted"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeShowPromoNotStarted">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__points points text-center">
          <div class="modal-subtitle points__title">Акция еще не началась, но начнется {{ state.promoStart }}.<br>Следите за обновлениями! </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPromoEnd" class="popup">
    <div class="popup__overlay" @click="closeShowPromoEnd"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeShowPromoEnd">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__points points text-center">
          <h5 class="modal-title points__title">Акция завершена.
            <br>Спасибо за участие!</h5>
          <div class="modal-subtitle">
            Вручение призов осуществляется до&nbsp;31.12.2025&nbsp;г.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* global ym */
import { getFeedbackTopics, fetchFAQ, getToken, getUserInfo, removeToken, authorize, register, getMainSettings, sendFeedback, setAuthHeader, sendResetPassword, fetchWinnings } from '@/api';
import metrikaMixin from '@/mixins/metrikaMixin';
import { state } from "@/store";
export default {
  name: 'NotFound',
  mixins: [metrikaMixin],
  setup() {
    return {
      state,
    };
  },
  data() {
    return {
      winners: [],
      limit: 10,
      offset: 0,
      prizeType: '',
      userEmail: '',
      totalCount: 0,
      loading: false,
      prizeTypes: [
        { value: '', label: 'Все призы' },
        { value: 'grand', label: 'Главный' },
        { value: 'monthly', label: 'Ежемесячный' },
      ],
      noWinners: false,
      isMenuOpen: false,
      formSending: false,
      resetPasswordEmail: null,
      isOpen: false,
      selectedText: 'Тема обращения',
      topics: [],
      utmParams: {},
      clientId: null,
      hasMoreWinners: true,
      isLoading: false,
      activePrizeType: 1,
      isHeaderScrolled: false,
      scrollThreshold: 0,
      rootUrl: window.location.origin,
      userId: '',
      userUuid: '',
      isMobileNavOpened: false,
      faq: [],
      prizes: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showPromoNotStarted: false,
      showPromoEnd: false,
      showEndPopupSuccess: false,
      showResetPasswordPopup: false,
      showBalanceInfo: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false,
        subject: '',
        files: []
      },
      filePreviews: [],
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        promocode: '',
        birthday: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: true
      },
      loginForm: {
        email: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      promoDates: '',
      mainSettings: {},
      isFocus: false,
      isChange: false,
      isGuarantedPopap: false,
    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeydown);
    document.removeEventListener('click', this.handleDynamicButtonClick);
  },
  async mounted() {
    this.loadWinners();
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    if (ref) {
      state.ref = ref;
    }
    await this.fetchTopics();
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keydown', this.handleKeydown);
    this.addIntersectionObserver();
    this.getClientId();
    this.getUTMParams();
    this.handleScroll();
    // this.fetchWinners();
    // this.fetchPrizes();
    setAuthHeader();
    const token = getToken();
    if (token) {
      setTimeout(async () => {
        getUserInfo().then(userInfo => {
          if (userInfo) {
            this.userId = userInfo.id;
            this.userUuid = userInfo.uuid;
            this.user = {
              first_name: userInfo.first_name,
              last_name_initial: userInfo.last_name.charAt(0),
            };
            this.questionForm.name = userInfo.first_name;
            this.questionForm.email = userInfo.email;
            // this.initQrWidget();
          }
        }).catch(error => {
          console.error('Failed to fetch user info on mount', error);
        });
      }, 900); // 500 мс задержка
    }
    window.addEventListener('scroll', this.handleScroll);
    document.querySelectorAll(".guaranteed-card").forEach(card => {
      card.addEventListener("click", () => {
        if (card.classList.contains("active")) {
          card.classList.remove("active");
        } else {
          document.querySelectorAll(".guaranteed-card").forEach(el => el.classList.remove("active"));
          card.classList.add("active");
        }
      });
    });
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    tgBotUrl() {
      return `${process.env.VUE_APP_TG_BOT_URL}`;
    },
    rulesUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/rules`;
    },
    policyUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/policy`;
    },
    agreementUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/agreement`;
    },
    eyeIcon() {
      return require('@/assets/img/icons/eye.svg');
    },
    eyeOffIcon() {
      return require('@/assets/img/icons/eye-off.svg');
    },
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  // mounted(){

  // },
  methods: {
    handleDynamicButtonClick(event) {
      if (event.target.id === 'apm-close-widget-button') {
        state.isUploadOpened = false;
        this.$sendMetrik('reachGoal', 'close_load');
        // this.executeAdriverEvent('close_load');
        // Логика обработки клика
      }
    },
    async loadWinners(reset = false) {
      if (this.loading) return;
      this.loading = true;

      if (reset) {
        this.offset = 0;
        this.winners = [];
      }

      try {
        const data = await fetchWinnings({
          limit: this.limit,
          offset: this.offset,
          prize_type: this.prizeType,
          user_email: this.userEmail,
        });
        this.winners = reset ? data.results : [...this.winners, ...data.results];
        this.totalCount = data.count;
        this.offset += this.limit;
        this.noWinners = this.winners.length === 0;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.toggleBodyScroll();
    },
    closeMenu() {
      this.isMenuOpen = false;
      this.toggleBodyScroll();
    },
    toggleBodyScroll() {
      if (this.isMenuOpen) {
        document.body.classList.add('noscroll');
      } else {
        document.body.classList.remove('noscroll');
      }
    },
    async fetchTopics() {
      try {
        this.topics = await getFeedbackTopics();
      } catch (error) {
        console.error('Error loading topics:', error);
      }
    },
    isOpenGuarantedPopap(){
      // console.log('e', e.target)
      // if(e.target.contains('active')){
      //   e.target.classList.remove('active')
      // } else {
      //   e.target.classList.add('active')
      // }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$nextTick(() => {
        if (this.$refs.dropDownBtn && this.$refs.dropDownList) {
          this.$refs.dropDownList.style.top = `${this.$refs.dropDownBtn.offsetHeight + 5}px`;
        }
      });
    },
    selectItem(item) {
      this.selectedText = item.title;
      this.questionForm.subject = item.id;
      this.isOpen = false;
      this.isChange = true;
    },
    handleClickOutside(event) {
      // Проверяем, что this.$refs.dropdown существует
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    handleKeydown(event) {
      if (event.key === 'Tab' || event.key === 'Escape') {
        this.isOpen = false;
      }
    },
    getClientId() {
      if (typeof ym !== 'undefined') {
        ym(99695781, 'getClientID', (clientId) => {
          this.clientId = clientId;
          this.getUTMParams();
        });
      } else {
        console.error('Yandex.Metrica не доступна');
      }
    },
    // executeAdriverEvent(page) {
    //   const sidMapping = {
    //     faq: 'click_faq',
    //     prizes: 'click_prizes',
    //     winners: 'click_winners',
    //     close_registration: 'abandoned_user_registration_close_form',
    //     close_load: 'abandoned_check_registration_close_form',
    //     click_greenfield: 'click_greenfield',
    //     click_jardin: 'click_jardin',
    //     click_magnit: 'click_magnit',
    //     click_home: 'click_main',
    //     upload_check_open: 'click_register_check',
    //     click_how_use: 'click_how_participate',
    //     click_account: 'click_lk',
    //     registration_success: 'successful_user_registration',
    //     timer_15: 'timer_15'
    //   };
    //
    //   if (!sidMapping[page]) return;
    //
    //   const sid = 229236; // ваш статический sid
    //   const bt = 62; // ваш статический bt
    //   const sz = sidMapping[page];
    //
    //   // Вызов Adriver-кода
    //   (function (e, n) {
    //     function o(e, n, o) {
    //       n = n || "&";
    //       o = o || "=";
    //       var d = [];
    //       for (var r in e)
    //         if (Object.prototype.hasOwnProperty.call(e, r)) {
    //           d.push(r + o + encodeURIComponent(e[r]));
    //         }
    //       return d.join(n);
    //     }
    //     function d(e, n) {
    //       var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
    //       return o ? decodeURIComponent(o[2]) : null;
    //     }
    //     var r,
    //         t,
    //         i,
    //         c,
    //         u;
    //     (r = e),
    //         (t = n),
    //         (i = document.domain),
    //         (c = { tail256: document.referrer || "unknown" }),
    //     void 0 !==
    //     (u = (function (e) {
    //       var n = {};
    //       if (e) {
    //         var o = e.split("&");
    //         for (var d in o)
    //           if (Object.prototype.hasOwnProperty.call(o, d)) {
    //             var r = o[d].split("=");
    //             void 0 !== r[0] &&
    //             void 0 !== r[1] &&
    //             (n[r[0]] = decodeURIComponent(r[1]));
    //           }
    //       }
    //       return n;
    //     })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
    //     null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")),
    //     t &&
    //     t.id &&
    //     null !== d(document, t.id) &&
    //     (r.suid = i + "_" + encodeURIComponent(d(document, t.id))),
    //         t && t.gid1
    //             ? (r.gid1 = t.gid1)
    //             : null !== d(document, "_ga") &&
    //             (r.gid1 = encodeURIComponent(d(document, "_ga"))),
    //         t && t.yid1
    //             ? (r.yid1 = t.yid1)
    //             : null !== d(document, "_ym_uid") &&
    //             (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))),
    //         (r.loc = encodeURIComponent(window.location.href)),
    //     r.custom && (r.custom = o(r.custom, ";")),
    //         (function (e, n) {
    //           !(function (e) {
    //             if (
    //                 ((e = e.split("![rnd]").join(~~(1e6 * Math.random()))),
    //                 document.createElement && document.body)
    //             ) {
    //               var n = document.createElement("img");
    //               (n.style.position = "absolute"),
    //                   (n.style.display = "none"),
    //                   (n.style.width = n.style.height = "0px"),
    //                   n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
    //                   (n.src = e),
    //                   document.body.appendChild(n);
    //             } else {
    //               var o = new Image();
    //               o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
    //                   (o.src = e);
    //             }
    //           })(
    //               "https://ad.adriver.ru/cgi-bin/rle.cgi?" +
    //               e +
    //               "&rnd=![rnd]" +
    //               (n ? "&" + n : "")
    //           );
    //         })(o(r), o(c));
    //   })({ sid: sid, bt: bt, sz: sz }, { id: "", gid1: "", yid1: "" });
    // },
    // executeDSPCounterEvent(siteArea) {
    //   const sid = 229303; // ваш статический sid
    //   const siteAreaMapping = {
    //     '30_sec': 'TJCuegy2',
    //     'login_success': 'qh1jImpT',
    //     'check_upload_success': 'hAf9oUO0',
    //     'buttons_click': 'bG46a4ie',
    //     'faq_click': 'USZY4vWn',
    //   };
    //   const siteAreaId = siteAreaMapping[siteArea] || 'USZY4vWn';
    //
    //   // Проверка и отложенный вызов если DSPCounter еще не загружен
    //   const checkDSPCounter = setInterval(() => {
    //     if (window.DSPCounter) {
    //       clearInterval(checkDSPCounter);
    //       window.DSPCounter('send', {
    //         'sid': sid,
    //         'site_area': siteAreaId,
    //         'user_id': '',
    //         'lead_id': '',
    //         'order_sum': ''
    //       });
    //     }
    //   }, 100); // проверяем каждые 100ms
    //
    // },
    SendMetrick(page) {
      this.$sendMetrik('reachGoal', page);
      // if (page == 'how-register') {
      //   this.$sendMetrik('reachGoal', 'click_how_use');
      //   // this.executeAdriverEvent('click_how_use');
      // } else if (page == 'faq') {
      //   this.$sendMetrik('reachGoal', 'click_faq');
      //   // this.executeAdriverEvent('faq');
      // } else if (page == 'prizes') {
      //   this.$sendMetrik('reachGoal', 'click_prize');
      //   // this.executeAdriverEvent('prizes');
      // } else if (page == 'winners') {
      //   this.$sendMetrik('reachGoal', 'click_winners');
      //   // this.executeAdriverEvent('winners');
      // }
    },
    addIntersectionObserver() {
      const options = {
        root: null, // используется viewport
        threshold: 0.10, // элемент должен быть виден на 25%
      };

      const observer = new IntersectionObserver(this.handleIntersection, options);

      // Находим все элементы с классом .js-animate-section
      const sections = document.querySelectorAll('.js-animate-section');

      // Наблюдаем за каждым элементом
      sections.forEach(section => {
        observer.observe(section);
      });
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-animated');
        }
      });
    },
    getUTMParams() {
      const params = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content'),
        click_id: params.get('click_id')
      };

      // Фильтрация null значений
      this.utmParams = Object.fromEntries(
          Object.entries(utmParams).filter(([, value]) => value !== null)
      );

      // Добавляем clientId в utmParams после его получения
      if (this.clientId) {
        this.utmParams.client_id = this.clientId;
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      // Фильтрация по разрешённым типам файлов и размеру
      const validFiles = files.filter(file => {
        const isValidType = /\.(jpg|jpeg|png|gif|tiff|webp|pdf)$/i.test(file.name);
        const isValidSize = file.size <= 500 * 1024 * 1024; // 500 MB
        return isValidType && isValidSize;
      });

      // Ограничение на 10 файлов
      if (validFiles.length + this.filePreviews.length > 10) {
        alert("Нельзя прикрепить больше 10 файлов.");
        return;
      }

      // Добавляем файлы в состояние и показываем их превью
      validFiles.forEach(file => {
        const preview = {
          file,
          name: file.name,
          type: file.type,
          preview: URL.createObjectURL(file) // для изображений
        };
        this.filePreviews.push(preview);
        this.questionForm.files.push(file);
      });
    },

    // Удаление файла
    removeFile(index) {
      // Удаляем файл из массива файлов
      this.filePreviews.splice(index, 1);
      this.questionForm.files.splice(index, 1);
    },
    handleRulesButtonClick() {
      this.$sendMetrik('reachGoal', 'rules');
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    async logout() {
      state.checkSoon = false;
      state.promoStatus = '';
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.user = {
        address: {
          apartment: null,
          city: null,
          email: null,
          fio: null,
          house: null,
          phone_number: null,
          street: null,
          zip_code: null,
        },
        bonus_checks_count: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        birthday: null,
        id: null,
        uuid: null,
        is_possible_to_upload_receipt: null,
        address_is_filled: null,
        promocode: null,
        referral_id: null,
        referral_link: null,
        balance: null,
        privilege_level: null,
        need_points: null
      };
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      window.location.href = '/';
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL, которые НЕ находятся внутри <a> тегов
      const urlPattern = /(?<!<a href=")(https?:\/\/[^\s]+)(?!")/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$sendMetrik('reachGoal', 'click_account');
      // this.executeAdriverEvent('click_account');
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage(page) {
      if (page == 'link') {
        this.$sendMetrik('reachGoal', 'click_home');
        // this.executeAdriverEvent('click_home');
      }
      this.$router.push('/');
    },
    logoLinkClick(page) {
      if (page == 'magnit') {
        this.$sendMetrik('reachGoal', 'click_magnit');
        // this.executeAdriverEvent('click_magnit');
      } else if (page == 'jardin') {
        this.$sendMetrik('reachGoal', 'click_jardin');
        // this.executeAdriverEvent('click_jardin');
      } else if (page == 'green') {
        this.$sendMetrik('reachGoal', 'click_greenfield');
        // this.executeAdriverEvent('click_greenfield');
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    formatBirthdayInput(event) {
      let value = event.target.value.replace(/\D/g, ''); // Оставляем только цифры

      // Ограничиваем длину до 8 символов
      if (value.length > 8) {
        value = value.slice(0, 8);
      }

      // Форматируем строку в DD-MM-YYYY
      let formattedValue = value;

      if (value.length >= 2) {
        formattedValue = value.slice(0, 2) + '-' + value.slice(2); // Добавляем дефис после двух первых цифр
      }
      if (value.length >= 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5); // Добавляем дефис после пяти символов
      }

      // Удаляем дефис, если длина строки меньше 5 или 2
      if (formattedValue.length < 5) {
        formattedValue = formattedValue.replace('-', '');
      }

      if (formattedValue.length < 2) {
        formattedValue = formattedValue.replace('-', '');
      }

      // Присваиваем форматированное значение в форму
      this.registerForm.birthday = formattedValue;
    },
    convertBirthdayFormat(date) {
      if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
      }
      return date;
    },
    triggerQrScan() {
      // this.executeDSPCounterEvent('buttons_click');
      if (state.promoStatus == 'start') {
        const qrElement = document.getElementById('apm-scan-qr');
        if (qrElement) {
          // this.$sendMetrik('reachGoal', 'upload_check_open');
          // this.executeAdriverEvent('upload_check_open');
          state.isUploadOpened = true;
          qrElement.click();
        }
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      } else if (state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoEnd = true;
      }
    },
    triggerQuestionForm() {
      const qrElement = document.getElementById('triggerQuestionForm');
      if (qrElement) {
        qrElement.click();
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    handleLinkClick(selector) {
      this.toggleMobileNav(); // Закрываем навигацию
      // Убираем символ "#" из selector перед отправкой в SendMetrick
      const cleanSelector = selector.replace('#', '');
      this.SendMetrick(cleanSelector);

      setTimeout(() => { // Добавляем небольшую задержку перед скроллом
        this.scrollToSection(selector);
      }, 300); // Задержка в 300 мс или на ваше усмотрение
    },
    scrollToSection(selector) {
      const target = document.querySelector(selector);
      if (target) {
        const offsetTop = target.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    },
    openAccountPopup() {
      this.addBodyClass();
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.removeBodyClass();
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.addBodyClass();
      this.showQuestionPopup = true;
      this.questionForm.name = state.user.first_name;
      this.questionForm.email = state.user.email;
    },
    closeQuestionPopup() {
      this.removeBodyClass();
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.addBodyClass();
      this.showQuestionPopupSuccess = true;
      this.resetForm();
    },
    closeQuestionPopupSuccess() {
      this.removeBodyClass();
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    closeEndPopupSuccess() {
      this.removeBodyClass();
      this.showEndPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
      this.addBodyClass();
    },
    closeResetPasswordPopup() {
      this.removeBodyClass();
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openBalanceInfoPopup() {
      this.showBalanceInfo = true;
      this.closeLoginPopup();
      this.addBodyClass();
    },
    closeBalanceInfoPopup() {
      this.removeBodyClass();
      this.showBalanceInfo = false;
      this.resetForm();
    },
    goBalanceClick() {
      this.removeBodyClass();
      this.showBalanceInfo = false;
      this.resetForm();
      if (state.user.first_name) {
        this.triggerQrScan();
      } else {
        this.openLoginPopup()
      }
    },
    openResetPasswordPopupSuccess() {
      this.addBodyClass();
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.resetPasswordEmail = null;
      this.removeBodyClass();
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    closeShowPromoNotStarted() {
      this.removeBodyClass();
      this.showPromoNotStarted = false;
    },
    closeShowPromoEnd() {
      this.removeBodyClass();
      this.showPromoEnd = false;
    },
    async submitQuestion() {
      try {
        this.formSending = true;
        this.feedbackErrors = {};

        // Валидация формы
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите почту';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите текст обращения';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!this.questionForm.subject || this.questionForm.subject === 'Выберите тему') {
          this.feedbackErrors.subject = 'Выберите тему';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          this.formSending = false;
          return;
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text,
          subject: this.questionForm.subject,
          files: this.questionForm.files // отправляем файлы
        });

        this.closeQuestionPopup();
        this.openQuestionPopupSuccess();
        this.formSending = false;
      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        this.formSending = true;
        const { email } = this.resetPasswordForm;
        this.resetPasswordErrors = {};

        // Валидация длины телефонного номера
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.resetPasswordErrors.email = 'Введите корректный email';
        }

        // Отправка данных формы через API
        await sendResetPassword({ email: email });

        // Успешная отправка, здесь можно закрыть попап
        this.resetPasswordEmail = email;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
        this.formSending = false;
      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.filePreviews = [];
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        subject: '',
        agree_personal_data: false,
        files: []
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        birthday: '',
        promocode: '',
        agree_personal_data: false,
        agree_rules: false,
        agree_to_advertising: true
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      // this.executeDSPCounterEvent('faq_click');
      event.target.classList.toggle('active');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('active');
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          state.user = {
            address: {
              apartment: userInfo.address.apartment,
              city: userInfo.address.city,
              email: userInfo.address.email,
              fio: userInfo.address.fio,
              house: userInfo.address.house,
              phone_number: userInfo.address.phone_number,
              street: userInfo.address.street,
              zip_code: userInfo.address.zip_code,
            },
            bonus_checks_count: userInfo.bonus_checks_count,
            id: userInfo.id,
            uuid: userInfo.uuid,
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
            birthday: userInfo.birthday,
            is_possible_to_upload_receipt: userInfo.is_possible_to_upload_receipt,
            address_is_filled: userInfo.address_is_filled,
            promocode: userInfo.promocode,
            referral_id: userInfo.referral_id,
            referral_link: userInfo.referral_link,
            balance: userInfo.balance,
            privilege_level: userInfo.privilege_level,
            need_points: userInfo.need_points,
          }
          // this.executeDSPCounterEvent('login_success');
          // this.questionForm.name = userInfo.first_name;
          // this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    formatDateRange(start_date, end_date) {
      const start = new Date(start_date);
      const end = new Date(end_date);

      const format = (date) =>
          String(date.getUTCDate()).padStart(2, "0") + "." +
          String(date.getUTCMonth() + 1).padStart(2, "0") + "." +
          date.getUTCFullYear();

      return `${format(start)} – ${format(end)}`;
    },
    formatDateStart(start_date) {
      const start = new Date(start_date);

      return String(start.getUTCDate()).padStart(2, "0") + "." +
          String(start.getUTCMonth() + 1).padStart(2, "0") + "." +
          start.getUTCFullYear();
    },
    formatDateEnd(end_date) {
      const end = new Date(end_date);

      return String(end.getUTCDate()).padStart(2, "0") + "." +
          String(end.getUTCMonth() + 1).padStart(2, "0") + "." +
          end.getUTCFullYear();
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();
        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
          this.promoDates = start_date + ' - ' + end_date;
          state.promoStatus = 'not-started'
          state.promoStart = start_date;
          state.promoStart = this.formatDateStart(start_date);
        } else if (now >= startDate && now < endDate) {
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
          this.promoDates = start_date + ' - ' + end_date;
          state.promoStatus = 'start'
        } else {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
          this.promoDates = startDate + ' - ' + end_date;
          state.promoStatus = 'end'
          state.promoEnd = this.formatDateEnd(end_date);
        }
        this.promoDates = this.formatDateRange(start_date, end_date);

        const promoShown = sessionStorage.getItem('promoShown');
        if (!promoShown && state.promoStatus === 'end') {
          this.showPromoEnd = true;
          sessionStorage.setItem('promoShown', 'true');
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      // Переводим разницу в миллисекундах в дни, часы и минуты
      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    handleParticipateButton() {
      this.openLoginPopup();
    },
    openLoginPopup() {
      this.$sendMetrik('reachGoal', 'click_account');
      // this.executeAdriverEvent('click_account');
      if (state.promoStatus == 'start' || state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showLoginPopup = true;
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showPromoNotStarted = true;
      }
    },
    closeLoginPopup() {
      this.removeBodyClass();
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.registerForm.promocode = state.ref;
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
      this.addBodyClass();
    },
    closeRegisterPopup() {
      this.$sendMetrik('reachGoal', 'close_registration');
      // this.executeAdriverEvent('close_registration');
      this.removeBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    closeRegisterFinishPopup() {
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.$sendMetrik('reachGoal', 'registration_success');
      // this.executeAdriverEvent('registration_success');
      this.addBodyClass();
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.removeBodyClass();
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer.replace("{rules_link}", this.mainSettings.rules)
        }));
      } catch (error) {
        console.error('Error fetching FAQ:', error);
      }
    },
    async login() {
      try {
        this.formSending = true;
        this.loginFormErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email)) {
          this.loginFormErrors.email = 'Введите корректный email';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          this.formSending = false;
          return;
        }

        await authorize(this.email, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.userUuid = userInfo.uuid;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.removeBodyClass();
              // this.initQrWidget();
              // this.executeDSPCounterEvent('login_success');
              setTimeout(() => {
                this.$router.push('/private');
              }, 500)
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
          this.formSending = false;
        }, 900); // 500 мс задержка

      } catch (error) {
        this.formSending = false;
        if (error.response.status === 553 || error.response.status === 554) {
          this.responseError = 'неверный логин или пароль';
        } else if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        this.formSending = true;
        const { first_name, last_name, phone_number, email, birthday, promocode, agree_personal_data, agree_rules, agree_to_advertising } = this.registerForm;

        // Форматируем дату только перед отправкой, но не меняем значение в поле
        const formattedBirthday = this.convertBirthdayFormat(birthday);

        // Форматируем телефон
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверки
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться на обработку персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем
        if (Object.keys(this.errors).length > 0) {
          this.formSending = false;
          return;
        }

        const utmParamsList = this.utmParams;
        let promoFormatted = '';

        if (state.ref !== '') {
          promoFormatted = state.ref;
        } else {
          promoFormatted = promocode;
        }

        // Отправляем запрос с форматированной датой
        await register(first_name, last_name, formattedPhoneNumber, email, formattedBirthday, promoFormatted, JSON.stringify(utmParamsList), agree_to_advertising);

        this.closeRegisterFinishPopup();
        this.openRegisterPopupSuccess();

        promoFormatted = '';
        state.ref = '';
        this.formSending = false;
      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.addBodyClass();
      this.showResetPasswordSuccess = false;
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
      this.responseError = '';
    }
  },
};
</script>

<style>
/* Ваши стили */
</style>
