export function sendMetrik(event, goal) {
    if (window.ym) {
        window.ym(99695781, event, goal);
    }
}

export function sendAdRiverMetrik(sz) {
    !function (e, n) {
        function o(e, n, o) {
            n = n || "&";
            o = o || "=";
            var d = [];
            for (var r in e) {
                if (Object.prototype.hasOwnProperty.call(e, r)) {
                    d.push(r + o + encodeURIComponent(e[r]));
                }
            }
            return d.join(n);
        }

        function d(e, n) {
            var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
            return o ? decodeURIComponent(o[2]) : null;
        }

        var r, t, i, c, u;
        r = e;
        t = n;
        i = document.domain;
        c = { tail256: document.referrer || "unknown" };

        u = (function (e) {
            var n = {};
            if (e) {
                var o = e.split("&");
                for (var d in o) {
                    if (Object.prototype.hasOwnProperty.call(o, d)) {
                        var r = o[d].split("=");
                        if (r[0] !== undefined && r[1] !== undefined) {
                            n[r[0]] = decodeURIComponent(r[1]);
                        }
                    }
                }
            }
            return n;
        })(window.location.search.substring(1));

        if (u.adrclid !== undefined) {
            r.fsid = u.adrclid;
        }
        
        if (d(document, "adrcid") !== null) {
            r.cid = d(document, "adrcid");
        }

        if (t && t.id && d(document, t.id) !== null) {
            r.suid = i + "_" + encodeURIComponent(d(document, t.id));
        }

        if (t && t.gid1) {
            r.gid1 = t.gid1;
        } else if (d(document, "_ga") !== null) {
            r.gid1 = encodeURIComponent(d(document, "_ga"));
        }

        if (t && t.yid1) {
            r.yid1 = t.yid1;
        } else if (d(document, "_ym_uid") !== null) {
            r.yid1 = encodeURIComponent(d(document, "_ym_uid"));
        }

        r.loc = encodeURIComponent(window.location.href);
        if (r.custom) {
            r.custom = o(r.custom, ";");
        }

        function sendPixel(e, n) {
            function loadImage(e) {
                e = e.split("![rnd]").join(~~(1e6 * Math.random()));
                if (document.createElement && document.body) {
                    var n = document.createElement("img");
                    n.style.position = "absolute";
                    n.style.display = "none";
                    n.style.width = n.style.height = "0px";
                    n.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
                    n.src = e;
                    document.body.appendChild(n);
                } else {
                    var o = new Image();
                    o.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
                    o.src = e;
                }
            }
            loadImage("https://ad.adriver.ru/cgi-bin/rle.cgi?" + e + "&rnd=![rnd]" + (n ? "&" + n : ""));
        }

        sendPixel(o(r), o(c));
    }({ sid: 229795, bt: 62, sz: sz }, { id: "", gid1: "", yid1: "" });

    // console.log('sz:', sz);
}
