import axios from 'axios';

// Константа для базового URL API
const API_URL = process.env.VUE_APP_API_URL;

// Функция для установки токена в заголовок Authorization
export const setAuthHeader = () => {
    const token = getToken();
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// Добавляем интерцептор для обработки ответов
axios.interceptors.response.use(
  response => response,
  error => {
      if (error.response && error.response.status === 401) {
          removeToken();
          delete axios.defaults.headers.common['Authorization'];
      }
      return Promise.reject(error);
  }
);

export async function fetchWinnings({ limit = 10, offset = 0, prize_type = '', user_email = '' }) {
    const params = new URLSearchParams({ limit, offset });
    if (prize_type) params.append('prize_type', prize_type);
    if (user_email) params.append('user_email', user_email);

    const response = await fetch(`${API_URL}/api/quiz/winnings?${params.toString()}`);
    if (!response.ok) throw new Error('Ошибка загрузки данных');
    return await response.json();
}

// Функция для выполнения запроса на получение данных о призах
// export const getWinningPrizes = async () => {
//     try {
//         setAuthHeader();
//         const response = await axios.get(`${API_URL}/api/quiz/winning-prizes`);
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching winning prizes', error);
//         throw error;
//     }
// };

// Функция для получения списка призов
// export const getPrizes = async () => {
//     try {
//         const response = await axios.get(`${API_URL}/api/quiz/prizes`);
//         return response.data.results; // Возвращаем только массив результатов
//     } catch (error) {
//         console.error('Error fetching prizes:', error);
//         throw error;
//     }
// };

// Функция для выполнения запроса на получение чеков пользователя
export const getUserChecks = async (offset, limit) => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/user/receipts?offset=${offset}&limit=${limit}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winning prizes', error);
        throw error;
    }
};

// Функции для работы с токенами, пользователем, настройками и т.д. остаются такими же, как у вас в текущем файле запросов
export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => localStorage.setItem('token', token);

export const removeToken = () => localStorage.removeItem('token');

export const getUserInfo = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/users/me`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user info', error);
        throw error;
    }
};

export const authorize = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/api/users/authorization`, {
            email,
            password,
        });
        setToken(response.data.access);
        setAuthHeader();
        return response.data;
    } catch (error) {
        console.error('Ошибка во время авторизации', error);
        throw error;
    }
};

export const register = async (first_name, last_name, phone_number, email, birthday, promocode, registration_params, agree_to_advertising) => {
    try {
        birthday = null;
        await axios.post(`${API_URL}/api/users/registration`, {
            first_name,
            last_name,
            phone_number,
            email,
            birthday,
            promocode,
            registration_params,
            agree_to_advertising
        });
    } catch (error) {
        console.error('Error during registration', error);
        throw error;
    }
};

export const changeProfile = async (first_name, last_name, email, phone_number) => {
    try {
        await axios.put(`${API_URL}/api/users/me`, {
            first_name,
            last_name,
            email,
            phone_number
        });
    } catch (error) {
        console.error('Error during profile update', error);
        throw error;
    }
};

export const changePassword = async (new_password) => {
    try {
        await axios.patch(`${API_URL}/api/users/change-password`, {
            new_password
        });
    } catch (error) {
        console.error('Error during profile update', error);
        throw error;
    }
};

export const getMainSettings = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/main/settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching main settings', error);
        throw error;
    }
};

export const fetchFAQ = async () => {
    const endpoint = `${API_URL}/api/feedback/faq?limit=80`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching FAQ:", error);
        throw error;
    }
};

export const sendFeedback = async (message) => {
    const endpoint = `${API_URL}/api/feedback/send`;
    const formData = new FormData();

    // Добавляем обычные поля
    formData.append('email', message.email);
    formData.append('name', message.name);
    formData.append('text', message.text);
    formData.append('topic', message.subject);

    // Добавляем файлы, если они есть
    if (message.files && message.files.length > 0) {
        message.files.forEach((file) => {
            // Проверка на тип файла
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/webp', 'application/pdf'];
            if (!allowedTypes.includes(file.type)) {
                console.error('Invalid file type:', file.type);
                return;
            }
            // Добавляем файл в FormData
            formData.append('files', file, file.name);
        });
    }

    try {
        const response = await axios.post(endpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};

export const sendResetPassword = async (email) => {
    const endpoint = `${API_URL}/api/users/recovery-password`;
    const formData = new FormData();

    formData.append('email', email.email);

    try {
        const response = await axios.post(endpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};

export const fetchTopics = async (limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`${API_URL}/api/feedback/topics`, {
            params: { limit, offset }
        });
        return response.data.results;
    } catch (error) {
        console.error("Ошибка загрузки тем:", error);
        throw error;
    }
};

export async function sendUserAddress(addressData) {
    try {
        const response = await axios.post(`${API_URL}/api/users/address`, {
            fio: addressData.fio,
            phone_number: addressData.phone_number,
            email: addressData.email,
            city: addressData.city,
            street: addressData.street,
            house: addressData.house,
            apartment: addressData.apartment,
            zip_code: addressData.zip_code,
        });
        return response.data;
    } catch (error) {
        console.error("Ошибка загрузки тем:", error);
        throw error;
    }
}

export const getFeedbackTopics = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/feedback/topics`);
        return response.data.results; // Возвращаем только массив тем
    } catch (error) {
        console.error('Error fetching feedback topics:', error);
        throw error;
    }
};

export const updateProfile = async (data) => {
    try {
        await axios.put(`${API_URL}/api/users/me`, data);
    } catch (error) {
        console.error('Ошибка при обновлении профиля', error);
        throw error;
    }
};

export const fetchWinningPrizes = async () => {
    const endpoint = `${API_URL}/api/quiz/user/prizes?limit=100`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching winning prizes:", error);
        throw error;
    }
};

export const fetchAvailablePrizes = async () => {
    const endpoint = `${API_URL}/api/quiz/prizes?type=guaranteed&limit=100`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching available prizes:", error);
        throw error;
    }
};

export const fetchAvailablePrizesFull = async () => {
    const endpoint = `${API_URL}/api/quiz/prizes?type=point&limit=100&privilege_levels=silver`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching available prizes:", error);
        throw error;
    }
};

export const fetchAvailablePrizesGold = async () => {
    const endpoint = `${API_URL}/api/quiz/prizes?type=point&limit=100&privilege_levels=gold`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching available prizes:", error);
        throw error;
    }
};

export const fetchAvailablePrizesPlatinum = async () => {
    const endpoint = `${API_URL}/api/quiz/prizes?type=point&limit=100&privilege_levels=platinum`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching available prizes:", error);
        throw error;
    }
};

export const selectPrize = (receiptId, prizeId) => {
    return axios.patch(`${API_URL}/api/quiz/receipts/${receiptId}/guaranteed-prize`, { prize: prizeId })
        .then(response => response.data)
        .catch(error => {
            console.error('Error selecting prize:', error);
            throw error;
        });
};

export const fetchAdditionalPrizeFile = async (additionalDataId) => {
    const endpoint = `${API_URL}/api/quiz/user/prizes/additional-data/${additionalDataId}`;
    try {
        const response = await axios.get(endpoint, {
            responseType: 'blob',
        });
        return response;
    } catch (error) {
        console.error("Error fetching additional prize file:", error);
        throw error;
    }
};

export const fetchBalanceHistory = async (limit = 10, offset = 0) => {
    const endpoint = `${API_URL}/api/users/balance-history?limit=${limit}&offset=${offset}`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching balance history:", error);
        throw error;
    }
};

export const buyPrize = async (prizeId) => {
    const endpoint = `${API_URL}/api/quiz/prizes/buy`;
    try {
        const response = await axios.post(endpoint, { prize: prizeId });
        return response.data;
    } catch (error) {
        console.error('Error buying prize:', error);
        throw error;
    }
};







export default {
    getToken,
    setToken,
    removeToken,
    getUserInfo,
    authorize,
    register,
    changeProfile,
    getMainSettings,
    fetchFAQ,
    sendFeedback,
    sendResetPassword,
    fetchTopics,
    sendUserAddress,
    updateProfile,
    fetchWinningPrizes,
    fetchAvailablePrizes,
    selectPrize,
    fetchBalanceHistory,
    fetchAdditionalPrizeFile,
    fetchAvailablePrizesFull,
    buyPrize,
    fetchAvailablePrizesPlatinum,
    fetchAvailablePrizesGold,
    fetchWinnings
};