export default {
    mounted(el) {
        function mask(event) {
            let keyCode = event.keyCode || event.which;
            let matrix = "+7 (___) ___-__-__";
            let def = matrix.replace(/\D/g, "");
            let val = el.value.replace(/\D/g, "");
            let new_value = "";

            if (val.length > 11) {
                val = val.slice(0, 11); // Ограничиваем длину до 11 цифр
            }

            let i = 0;
            new_value = matrix.replace(/[_\d]/g, (a) => {
                return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
            });

            i = new_value.indexOf("_");
            if (i !== -1) {
                new_value = new_value.slice(0, i);
            }

            let reg = matrix
                .substr(0, el.value.length)
                .replace(/_+/g, (a) => `\\d{1,${a.length}}`)
                .replace(/[+()]/g, "\\$&");
            reg = new RegExp(`^${reg}$`);

            if (!reg.test(el.value) || el.value.length < 5 || keyCode >= 48 && keyCode <= 57) {
                el.value = new_value;
            }

            // Сохраняем позицию курсора
            if (event.type === "input" && el.selectionStart < el.value.length) {
                el.selectionStart = el.value.length;
                el.selectionEnd = el.value.length;
            }
        }

        function handleBackspace(event) {
            if (event.key === "Backspace") {
                let pos = el.selectionStart;
                if (pos > 0) {
                    let valueBeforeCursor = el.value.slice(0, pos);
                    if (/[\s()-]/.test(valueBeforeCursor.slice(-1))) {
                        // Пропустить символ форматирования
                        event.preventDefault();
                        el.setSelectionRange(pos - 1, pos - 1);
                    }
                }
            }
        }

        el.addEventListener("input", mask, false);
        el.addEventListener("focus", mask, false);
        el.addEventListener("blur", mask, false);
        el.addEventListener("keydown", handleBackspace, false);
    }
};