import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import PrivatePage from '@/views/PrivatePage.vue';
import NotFound from '@/views/NotFound.vue';
import { getToken } from '@/api';
import { getMainSettings } from '@/api'; // Импортируем ваш метод
import { sendAdRiverMetrik } from '@/utils/metrika';

const routes = [
  { path: '/', component: HomePage, meta: { title: 'Главная страница', description: 'Добро пожаловать на главную страницу' } },
  {
    path: '/private',
    component: PrivatePage,
    meta: { requiresAuth: true, title: 'Частная страница', description: 'Только для авторизованных пользователей' },
  },
  { path: '/:catchAll(.*)', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!getToken();

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

router.afterEach(async (to, from) => {
  // Загружаем главные настройки с API
  const metaInfo = to.meta;
  if (metaInfo) {
    let title = metaInfo.title || 'Расширяем привилегии GREENFIELD CLUB';
    let description = metaInfo.description || 'Присоединяйтесь к программе лояльности Greenfield Club, регистрируйте чеки на сайте или чат-боте, выбирайте подарки от любимого бренда, а также участвуйте в розыгрышах призов.';

    // Получаем настройки с API для главной страницы или других страниц
    if (to.path === '/') {
      const settings = await getMainSettings();  // Вызываем метод из api.js
      title = settings.title || title;
      document.title = 'GREENFIELD CLUB';
      description = settings.description || description;
    }

    if (to.path === '/private') {
      const settings = await getMainSettings();  // Вызываем метод из api.js
      title = settings.title || title;
      document.title = 'Личный кабинет | GREENFIELD CLUB';
      description = settings.description || description;
    }

    // Обновляем мета-теги
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    const ogDescriptionMetaTag = document.querySelector('meta[property="og:description"]');
    const twitterDescriptionMetaTag = document.querySelector('meta[name="twitter:description"]');

    const ogTitle = document.querySelector('meta[property="og:title"]');
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');

    if (ogTitle) {
      ogTitle.setAttribute('content', title);
      twitterTitle.setAttribute('content', title);
    }
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', description);
      ogDescriptionMetaTag.setAttribute('content', description);
      twitterDescriptionMetaTag.setAttribute('content', description);
    }
  }
  // if (to.fullPath.split('#')[0] !== from.fullPath.split('#')[0]) {
    if (to.path !== from.path) {
      sendAdRiverMetrik("all-pages");
    }
});

export default router;
