<template>
  <div class="wrapper">
    <div id='overlay' :class="{ active: isMenuOpen }" @click="closeMenu"></div>
    <div class="mobile-menu" :class="{ active: isMenuOpen }" @click="closeMenu">
      <div class="header__content">
        <nav class="nav">
          <!--            @click="SendMetrick('how-register')"-->
          <a href="/#guaranteed" class="nav__link" @click="SendMetrick('header-prizes')">Призы</a>
          <a href="/#winners" class="nav__link" @click="SendMetrick('header-winners')">Победители</a>
          <a href="/#faq" class="nav__link" @click="SendMetrick('header-faq')">Вопрос-ответ</a>
        </nav>
        <div class="header__controls">
          <a :href="rulesUrl" class="header__rules" @click="handleRulesButtonClick()" target="_blank">
            <span>Правила акции</span>
            <svg width='20' height='20'><use href='@/assets/img/icons/icons.svg#download'></use></svg>
          </a>
          <button v-if="!user" @click="openLoginPopup" class="header__btn">
            Личный кабинет
          </button>
          <template v-else>
            <div class="header__btns">
              <button class="header__btn" @click="redirectToPrivatePage">
                <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
              </button>
              <button class="header__btn" @click="logout">
                <svg width='24' height='24'><use href='@/assets/img/icons/icons.svg?v_2#log-out'></use></svg>
              </button>
            </div>            
          </template>
        </div>
      </div>
    </div>
    <header class="header" id="header">
      <div class="container">
        <div class="header__container">
          <a @click="redirectToHomePage('link')" href="#" class="logo">
            <img loading='lazy' width="216" height="102" src="@/assets/img/logo.webp" srcset="@/assets/img/logo@2x.webp 2x" alt="logotip">
          </a>
          <div class="header__content">
            <nav class="nav">
              <a href="/#guaranteed" class="nav__link" @click="SendMetrick('header-prizes')">Призы</a>
              <a href="/#winners" class="nav__link" @click="SendMetrick('header-winners')">Победители</a>
              <a href="/#faq" class="nav__link" @click="SendMetrick('header-faq')">Вопрос-ответ</a>
            </nav>
            <div class="header__controls">
              <a :href="rulesUrl" class="header__rules" @click="handleRulesButtonClick()" target="_blank">
                <span>Правила акции</span>
                <svg width='20' height='20'><use href='@/assets/img/icons/icons.svg#download'></use></svg>
              </a>
              <button v-if="!user" @click="openLoginPopup" class="header__btn">
                Личный кабинет
              </button>
              <template v-else>
                <button class="header__btn" @click="redirectToPrivatePage">
                  <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
                </button>
                <button class="header__btn" @click="logout">
                  <svg width='24' height='24'><use href='@/assets/img/icons/icons.svg?v_2#log-out'></use></svg>
                </button>
              </template>
            </div>
          </div>
          <div class="nav-icon" :class="{ active: isMenuOpen }" @click="toggleMenu"><span></span></div>
        </div>
      </div>
    </header>
    <main class="main" >
      <section class="main-screen-lc" id="mainScreen">
        <div class="main-screen-lc-wr">
          <div class="container">
            <h1 class="main-screen-lc__title title2">Личный кабинет</h1>
            <div class="main-screen-lc__links">
              <a href="#mainScreen" class="main-screen-lc__link btn --border active">Мои баллы</a>
              <a href="#myPrizes" class="main-screen-lc__link btn --border">МОИ ПРИЗЫ</a>
              <a href="#exchangePoints" class="main-screen-lc__link btn --border">ОБМЕНЯТЬ БАЛЛЫ</a>
              <a href="#checks" class="main-screen-lc__link btn --border" @click.prevent="openChecksTab">ЗАГРУЖЕННЫЕ ЧЕКИ</a>
              <a href="#checks" class="main-screen-lc__link btn --border" @click.prevent="openHistoryTab">ИСТОРИЯ ОПЕРАЦИЙ</a>
            </div>
            <div ref="dropdown" class="main-screen-lc__dropdown dropdown">
              <button
                  class="dropdown__button"
                  :class="{ 'dropdown__button--active': isOpenDropdownMobile }"
                  @click="toggleDropdown"
              >
                {{ selectedText }}
              </button>
              <div v-if="isOpenDropdownMobile" class="dropdown__list dropdown__list--visible" :style="dropdownStyle">
                <div class="dropdown__list-scroll">
                  <a href="#mainScreen" class="dropdown__list-item" @click.prevent="scrollToSection('#mainScreen')">Мои баллы</a>
                  <a href="#myPrizes" class="dropdown__list-item" @click.prevent="scrollToSection('#myPrizes')">МОИ ПРИЗЫ</a>
                  <a href="#exchangePoints" class="dropdown__list-item" @click.prevent="scrollToSection('#exchangePoints')">ОБМЕНЯТЬ БАЛЛЫ</a>
                  <a
                      v-for="(item, index) in items"
                      :key="index"
                      href="javascript:void(0)"
                      class="dropdown__list-item"
                      @click.prevent="selectItem(item)"
                  >
                    {{ item.text }}
                  </a>
                </div>
              </div>
            </div>

            <div class="lc">
              <div class="lc__info">
                <div class="lc__info-header lc__info-grid">
                  <h3 class="lc__info-name">{{ state.user.first_name }} {{ state.user.last_name }}</h3>
<!--                  <div v-if="state.user.birthday" class="lc__info-info">-->
<!--                    <span class="lc__info-info-dt">Дата рождения:</span>-->
<!--                    <span class="lc__info-info-dd">-->
<!--                      {{ state.user.birthday }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div v-else>-->
<!--                    <p>Получите +20 баллов в подарок на свой день рождения!</p>-->
<!--                    <button class="lc__info-btn-add" @click="openBirthdayPopup()">Указать дату рождения</button>-->
<!--                  </div>-->
                </div>
                <div class="lc__info-body lc__info-grid">
                  <span class="lc__info-link">{{ state.user.email }}</span>
                  <span class="lc__info-link">{{ formatPhone(state.user.phone_number) }}</span>
                </div>
                <div class="lc__info-footer lc__info-grid">
                  <h4 class="title4 lc__info-address-title">Адрес доставки</h4>
                  <div v-if="!isAddressFilled">
                    <p>Укажите свой почтовый адрес, для доставки физических подарков.</p>
                    <button class="lc__info-btn-add" @click="openAddressPopup()">Указать адрес доставки</button>
                  </div>
                  <div v-else class="lc__info-info lc__info-grid">
                    <span class="lc__info-info-dt">Почтовый адрес, для доставки физических подарков.</span>
                    <span class="lc__info-link">
                      г.{{ state.user.address.city }}, {{ state.user.address.street }} д.{{ state.user.address.house }}, кв.{{ state.user.address.apartment }}, {{ state.user.address.zip_code }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="lc__points">
                <div class="lc__points-card">
                  <div class="lc__points-header">
                    <div class="lc__points-point">
                      <div class="lc__points-number">
                        <h3>{{ state.user.balance }}</h3>
                        <span>баллов</span>
                      </div>
                      <span class="lc__points-name">{{ state.user.privilege_level }}</span>
                    </div>
                    <img v-if="state.user.privilege_level == 'silver'" class='' width='279' height='176' src='@/assets/img/privilege/card_1.webp' srcset='@/assets/img/privilege/card_1@2x.webp 2x' >
                    <img v-if="state.user.privilege_level == 'gold'" class='' width='279' height='176' src='@/assets/img/privilege/card_2.webp' srcset='@/assets/img/privilege/card_2@2x.webp 2x' >
                    <img v-if="state.user.privilege_level == 'platinum'" class='' width='279' height='176' src='@/assets/img/privilege/card_3.webp' srcset='@/assets/img/privilege/card_3@2x.webp 2x' >
                  </div>
                  <div class="lc__points-body range">
                    <div class="range__track">
                      <div
                          class="range__thumb"
                          :style="{ width: thumbWidth === 14 ? '1.85rem' : thumbWidth + '%' }"
                      ></div>
                    </div>
                    <div class="range__lavels">
                      <span>Silver</span>
                      <span>Gold</span>
                      <span>Platinum</span>
                    </div>
                  </div>
                  <div class="lc__points-count">
                    <span>Баллов до следующего уровня:</span>
                    <span>
                      {{ state.user.need_points }}
                    </span>
<!--                    <span v-if="state.user.privilege_level == 'silver'">{{ 500 - state.user.balance }}</span>-->
<!--                    <span v-else-if="state.user.privilege_level == 'gold'">{{ 500 - state.user.balance }}</span>-->
<!--                    <span v-else-if="state.user.privilege_level == 'platinum'">0</span>-->
                  </div>
                </div>
                <div class="lc__points-footer">
                  <ul>
                    <li>Копите баллы и повышайте уровень привилегий.</li>
                    <li>Обменивайте баллы на подарки и участвуйте в розыгрыше призов от Greenfield!</li>
                  </ul>
                  <div class="lc__points-btns">
                    <button class="lc__points-btn btn"
                            @click="triggerQrScan"
                            :disabled="state.isUploadOpened">Загрузить чек</button>
                    <a href="#exchangePoints" class="lc__points-btn btn --border">Обменять баллы</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="invate" id="invateFriendes">
        <div class="container">
          <div class="invate__container">
            <img class="invate__img" width="830" height="460" src="@/assets/img/main/meg.webp" srcset="@/assets/img/main/meg@2x.webp 2x" />
            <div class="invate__content">
              <h2 class="invate__title title2">Приглашайте друзей!</h2>
              <div class="invate__desc">
                <p>Получайте +1 шанс в розыгрыше главного приза за каждого приглашенного друга! Шанс дается за каждого друга, который зарегистрируется с указанием Вашего промокода и загрузит валидный чек.</p>
              </div>
              <div class="invate__promo">
                <a href="#" class="invate__link-copy link-copy" @click.prevent="copyReferralLink">
                  <div class="link-copy__content">
                    <span class="link-copy__text">Ссылка для приглашения:</span>
                    <span class="link-copy__number">{{ state.user.referral_id }}</span>
                  </div>
                  <svg width="24" height="24"><use href="@/assets/img/icons/icons.svg#copy"></use></svg>
                </a>
                <div class="chance">
                  {{ formattedBonusChecks }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="prizes" id="myPrizes">
        <div class="container">
          <h2 class="prizes__title title2 text-center">Мои призы</h2>
          <div class="empty" v-if="!prizes || prizes.length === 0">
            <div class="empty__text is-center">
              <a @click="triggerQrScan" style="text-decoration: underline;">Загрузите первый чек,</a> и после успешной модерации Вас ждет гарантированный приз!
            </div>
          </div>
          <div class="prizes__container">
            <div v-for="(item, index) in prizes" :key="index" class="card-prize">
              <img
                  v-if="item.prize"
                  :src="item.prize.image || '@/assets/img/my_prize/gift_1.webp'"
                  alt="Prize Image"
                  class="card-prize__img full-img"
                  width="240"
                  height="240"
              />
              <img
                  v-else
                  src="@/assets/img/my_prize/gift_1.webp"
                  srcset="@/assets/img/my_prize/gift_1@2x.webp 2x"
                  alt="Prize Image"
                  class="card-prize__img full-img"
                  width="240"
                  height="240"
              />
              <div class="card-prize__content">
                <span class="card-prize__text-btn">
                  <span v-if="!item.prize">
                    гарантированный
                  </span>
                  <span v-else>
                    {{ getPrizeType(item.prize) }}
                  </span>
                </span>
                <h6 class="card-prize__title">{{ item.prize ? item.prize.title : 'Выберите подарок' }}</h6>

                <!-- Если это электронный сертификат -->
                <a v-if="item.additional_data && item.prize && item.additional_data.type === 'electronic_certificate'"
                   class="card-prize__link-copy link-copy"
                   @click.prevent="downloadCertificate(item.additional_data.id)">
                  <div class="link-copy__content">
                    <span class="link-copy__text">PDF-сертификат</span>
                    <span class="link-copy__number">{{ item.additional_data.number }}</span>
                  </div>
                  <svg width="24" height="24"><use href="@/assets/img/icons/icons.svg#download"></use></svg>
                </a>

                <!-- Если это электронный сертификат -->
                <a v-if="item.additional_data && item.prize && item.additional_data.type === 'promo_code'"
                   class="card-prize__link-copy link-copy"
                   @click="copyToClipboard(item.additional_data.number)">
                  <div class="link-copy__content">
                    <span class="link-copy__text">Промокод</span>
                    <span class="link-copy__number">{{ item.additional_data.number }}</span>
                  </div>
                  <svg width="24" height="24"><use href="@/assets/img/icons/icons.svg#copy"></use></svg>
                </a>

                <!-- Если это накопительный приз с трек-номером -->
                <a v-if="item.track_number" class="card-prize__link-copy link-copy" @click="copyToClipboard(item.track_number)">
                  <div class="link-copy__content">
                    <span class="link-copy__text">Трек-номер:</span>
                    <span class="link-copy__number">{{ item.track_number }}</span>
                  </div>
                  <svg width="24" height="24"><use href="@/assets/img/icons/icons.svg#copy"></use></svg>
                </a>

                <button v-if="item.prize && item.prize.type === 'guaranteed'"
                        class="card-prize__btn-info"
                        @click="openShowInfoPopup(
                          item.prize.greeting_text,
                          'Как воспользоваться?',
                          item.additional_data ? item.additional_data.type : '',
                          item.additional_data ? item.additional_data.number : '',
                          item.additional_data ? item.additional_data.id : ''
                        )">
                  Как воспользоваться?
                </button>
                <button v-else-if="item.prize"
                        class="card-prize__btn-info"
                        @click="openShowInfoPopup(
                          item.prize.greeting_text,
                          'Информация о получении',
                          item.additional_data ? item.additional_data.type : '',
                          item.additional_data ? item.additional_data.number : '',
                          item.additional_data ? item.additional_data.id : ''
                        )">
                  Информация о получении
                </button>
                <button v-if="!item.prize && !item.selected" @click="selectPrize(item.receipt.id,state.user.privilege_level)" class="card-prize__btn btn">
                  Выбрать подарок
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="exchange-points" id="exchangePoints">
        <div class="container">
          <h2 class="exchange-points__title title2 text-center">Обменять баллы</h2>
          <div class="exchange-points__header header-levels">
            <span class="subtitle">Доступны уровням</span>
            <div class="header-levels__btns">
              <span class="header-levels__btn">Silver</span>
              <span class="header-levels__btn">GOLD</span>
              <span class="header-levels__btn">Platinum</span>
            </div>
          </div>
          <div class="exchange-points__slider">
            <div class="exchange-points__swiper swiper">
              <div class="swiper-wrapper">
                <div
                    v-for="prize in prizesFull"
                    :key="prize.id"
                    class="swiper-slide gift-card exchange-points__gift-card"
                    :class="{ '--not-enough': !prize.balance_enough }"
                >
                  <div class="gift-card__image" :data-points="prize.points + ' баллов'">
                    <img class='full-img' width='284' height='284' :src="prize.image" :srcset="prize.image + ' 2x'" />                   
                  </div>
                  <h6 class="gift-card__title">{{ prize.title }}</h6>
                  <button
                      class="gift-card__btn btn --border"
                      :disabled="!prize.balance_enough"
                      @click="openChangePopup(prize.title, prize.id, prize.points)"
                  >
                    {{ prize.balance_enough ? 'Получить' : 'Получить' }}
                  </button>
                </div>
              </div>
              <div class="exchange-points__pagination pagination"></div>
            </div>

            <div
                class="exchange-points__prev swiper-button --small"
                ref="prevButton"
            >
              <svg width="28" height="29" viewBox="0 0 28 29" fill="" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.5L9 14.5L20 25.5" stroke=""/>
              </svg>
            </div>

            <div
                class="exchange-points__next swiper-button --small"
                ref="nextButton"
            >
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3L20 14L9 25" stroke=""/>
              </svg>
            </div>
          </div>

          <div class="exchange-points__header header-levels">
            <span class="subtitle">Доступны уровням</span>
            <div class="header-levels__btns">
              <span class="header-levels__btn">GOLD</span>
              <span class="header-levels__btn">Platinum</span>
            </div>
          </div>
          <div class="exchange-points__slider">
            <div class="exchange-points-sert__swiper swiper">
              <div v-if="state.user.privilege_level === 'silver'" class="exchange-points__wrapper">
                <svg width='87' height='87'><use href='@/assets/img/icons/icons.svg?v_1#lock'></use></svg>
                <p>Для участия в розыгрыше призов необходимо повысить уровень привилегий.
                  Загружайте чеки и повышайте уровень!</p>
                <button class="btn --border" @click="triggerQrScan" :disabled="state.isUploadOpened">Загрузить чек</button>
              </div>
              <div class="swiper-wrapper">
                <div v-for="prize in prizesGold" :key="prize.id" class="swiper-slide gift-card exchange-points__gift-card --before-center" :class="{ '--not-enough': !prize.balance_enough }">
                  <div class="gift-card__image" :data-points="prize.points + ' баллов'">
                    <img class='full-img' width='284' height='284' :src="prize.image" :srcset="prize.image + ' 2x'" />    
                  </div>
<!--                  <h6 class="gift-card__title">{{ prize.title }}</h6>-->
                  <button class="gift-card__btn btn --border" :disabled="!prize.balance_enough" @click="openChangePopup(prize.title, prize.id, prize.points)">
                    {{ prize.balance_enough ? 'Получить' : 'Получить' }}
                  </button>
                </div>
              </div>
              <div class="exchange-points-sert__pagination pagination"></div>
            </div>
            <div class="exchange-points-sert__prev swiper-button --small">
              <svg width="28" height="29" viewBox="0 0 28 29" fill="" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.5L9 14.5L20 25.5" stroke=""/>
              </svg>
            </div>
            <div class="exchange-points-sert__next swiper-button --small">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3L20 14L9 25" stroke=""/>
              </svg>
            </div>
          </div>
        </div>
      </section>

      <section class="checks" id="checks">
        <div class="container">
          <div class="checks__tab-btns">
            <button
                v-for="(tab, index) in tabs"
                :key="index"
                class="checks__tab-btn title2 text-transperent"
                :class="{ active: activeTab === index }"
                @click="activeTab = index"
            >
              {{ tab.label }}
            </button>
          </div>
          <div
              v-for="(tab, index) in tabs"
              :key="index"
              class="checks__tab-content"
              :class="{ active: activeTab === index }"
          >
            <!-- Статическое содержимое -->
            <div v-if="index === 0">
              <div class="empty" v-if="state.checks.results.length === 0">
                <div class="empty__text">
                  <p>Загрузите свой первый чек</p>
                  <p>
                    <button class="lc__points-btn btn"
                            @click="triggerQrScan"
                            :disabled="state.isUploadOpened">Загрузить чек</button>
                  </p>
                </div>
              </div>
              <ul class="tab-content" v-else>
                <li v-if="state.checkSoon" class="tab-content__row">
                  <span class="tab-content__item">xx.xx.xxxx</span>
                  <div class="tab-content__item">
                    <img class='full-img' width='48' height='48' src='@/assets/img/check.webp' >
                    <span>XXXXXXXX</span>
                  </div>
                  <span class="tab-content__item">
                  <span>Загружается</span>
                </span>
                </li>
                <li v-for="(check, cIndex) in state.checks.results" :key="check.id" :class="['tab-content__row', getStatusClass(check.status)]">
                  <span class="tab-content__item">{{ formatDateBalance(check.upload_date) }}</span>
                  <div class="tab-content__item">
                    <div v-if="check.images" class="check-item__img">
                      <a v-for="(image, index) in check.images" :key="index" :href="getImageSrc(image)" :data-fancybox="'user-check-' + cIndex">
                        <img :src="getImageSrc(image)" class="full-img" />
                      </a>
                    </div>
                    <span>{{ check.number }}</span>
                  </div>
                  <span class="tab-content__item">
                  <span v-if="check.status === 'rejected' && check.reject_reason !== ''" class="tab-content__error">{{ check.reject_reason }}</span>
                  <span>{{ getStatusText(check.status) }}</span>
                  <svg v-if="check.status === 'rejected' && check.reject_reason !== ''" class="tab-content__icon" width='24' height='24'><use href='@/assets/img/icons/icons.svg?v#help-circle'></use></svg>
                </span>
                </li>
              </ul>
              <p><br></p>
              <button v-if="state.checks.next"  class="checks__btn btn --border" @click="loadMoreChecks">
                Загрузить ещё
              </button>
            </div>
            <div v-if="index === 1">
              <div class="empty" v-if="!this.history.length">
                <div class="empty__text">
                  <p>Загрузите свой первый чек</p>
                  <p>
                    <button class="lc__points-btn btn"
                            @click="triggerQrScan"
                            :disabled="state.isUploadOpened">Загрузить чек</button>
                  </p>
                </div>
              </div>
              <ul class="tab-content" v-else>
                <li v-for="(item, index) in history" :key="index" class="tab-content__row">
                  <span class="tab-content__item">{{ formatDateBalance(item.created_at) }}</span>
                  <div class="tab-content__item">
                    <span v-if="item.operation_type === 'referral_bonus'">{{ getOperationLabel(item.operation_type) }}</span>
                    <span v-else-if="item.operation_type === 'manual_points'">{{ getOperationLabel(item.operation_type) }}</span>
                    <span v-else>{{ getOperationLabel(item.operation_type) }} {{ item.source_title }}</span>
                  </div>
                  <div class="tab-content__item">
                    {{ formatAmount(item.amount) }}
                  </div>
                </li>
              </ul>
              <p><br></p>
              <button v-if="nextPage" @click="loadMore" class="checks__btn btn --border" >Загрузить ещё</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>

  <!-- Popup для изменения пароля -->
  <div v-if="showAccountChangePasswordPopup" class="popup">
    <div class="popup__overlay" @click="closeAccountChangePasswordPopup"></div>
    <div class="popup__window">
      <button @click="closeAccountChangePasswordPopup" class="popup__close"></button>
      <div class="popup__title">Изменить пароль</div>
      <div class="popup__content">
        <form @submit.prevent="changeAccountPassword" autocomplete="off">
          <!-- Поле пароля -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="password">Пароль</label>
              <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password"
                  v-model="password"
                  name="password"
              />
              <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
            </div>
            <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
          </div>
          <!-- Поле подтверждения пароля -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="password">Подтверждение пароля</label>
              <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password_check"
                  v-model="password_check"
                  name="password"
              />
              <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
            </div>
            <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password_check }}<br></span>
          </div>
          <!-- Кнопка для отправки формы регистрации -->
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="error-message" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-main button-main--big button-main--full"
                      :disabled="!isFormValid">
                <span>Изменить пароль</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showAccountChangePasswordSuccess" class="popup">
    <div class="popup__overlay" @click="closeAccountChangePasswordSuccess"></div>
    <div class="popup__window">
      <button @click="closeAccountChangePasswordSuccess" class="popup__close"></button>
      <div class="popup__content">
        <div class="success-message">
          Ваш пароль успешно обновлён
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для добавление адреса -->
  <div v-if="showUserAddressFormPopup" class="popup">
    <div class="popup__overlay" @click="closeUserAddressFormPopup"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeUserAddressFormPopup">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg?v#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Данные для доставки подарка</h5>
        <form class="form form-grid" @submit.prevent="sendUserAddressForm" autocomplete="off">
          <div class="form__block-inputns --grid-two-col">
            <label class="form__wrap">
              <div class="form__inner-input">
                <input class="form__input"
                       v-model="addressForm.fio"
                       @input="validateCyrillic"
                       @blur="clearError('fio')"
                       :class="{ 'is-not-valid': errors.fio }"
                       name="address-fio"
                       type="text" placeholder=""
                       required>
                <span class="form__name">ФИО</span>
              </div>
              <span v-if="errors.fio" class="error-message">{{ errors.fio }}</span>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input type="text" maxlength="18" id="address-phone" v-model="addressForm.phone_number" name="phone_number"
                       autocomplete="off"
                       class="form__input"
                       placeholder=""
                       required
                       @blur="clearError('phone_number')"
                       :class="{ 'is-not-valid': errors.phone_number }"
                       v-mask>
                <span class="form__name">Номер телефона</span>
              </div>
              <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input
                    class="form__input"
                    placeholder=""
                    required
                    type="text"
                    maxlength="255"
                    id="address-email"
                    @blur="clearError('email')"
                    v-model="addressForm.email"
                    :class="{ 'is-not-valid': errors.email }"
                    name="address-email"
                />
                <span class="form__name">Почта</span>
              </div>
              <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input
                    class="form__input form__input--padding"
                    placeholder=""
                    required
                    type="text"
                    maxlength="255"
                    id="address-city"
                    @blur="clearError('city')"
                    v-model="addressForm.city"
                    :class="{ 'is-not-valid': errors.city }"
                    name="address-city"
                />
                <span class="form__name">Населенный пункт</span>
              </div>
              <span v-if="errors.city" class="error-message">{{ errors.city }}</span>
              <InfoBlock>
                <div class="info-block__content">
                  Город/поселок/село
                </div>
              </InfoBlock>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input
                    class="form__input"
                    placeholder=""
                    type="text"
                    maxlength="255"
                    id="address-street"
                    required
                    @blur="clearError('street')"
                    v-model="addressForm.street"
                    :class="{ 'is-not-valid': errors.street }"
                    name="address-street"
                />
                <span class="form__name">Улица</span>
              </div>
              <span v-if="errors.street" class="error-message">{{ errors.street }}</span>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input
                    class="form__input"
                    placeholder=""
                    required
                    type="text"
                    maxlength="255"
                    id="address-house"
                    @blur="clearError('house')"
                    v-model="addressForm.house"
                    :class="{ 'is-not-valid': errors.house }"
                    name="address-house"
                />
                <span class="form__name">Номер дома</span>
              </div>
              <span v-if="errors.house" class="error-message">{{ errors.house }}</span>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input
                    class="form__input form__input--padding"
                    placeholder=""
                    required
                    type="text"
                    maxlength="255"
                    id="address-apartment"
                    @blur="clearError('apartment')"
                    v-model="addressForm.apartment"
                    :class="{ 'is-not-valid': errors.apartment }"
                    name="address-apartment"
                />
                <span class="form__name">Квартира</span>
              </div>
              <span v-if="errors.apartment" class="error-message">{{ errors.apartment }}</span>
              <InfoBlock>
                <div class="info-block__content">
                  Если не актуально,
                  <br>поставьте прочерк «-»
                </div>
              </InfoBlock>
            </label>
            <label class="form__wrap">
              <div class="form__inner-input">
                <input
                    class="form__input"
                    placeholder=""
                    required
                    type="text"
                    maxlength="6"
                    id="address-house"
                    @input="filterZipCode"
                    @blur="clearError('zip_code')"
                    v-model="addressForm.zip_code"
                    :class="{ 'is-not-valid': errors.zip_code }"
                    name="address-zip_code"
                />
                <span class="form__name">Индекс</span>
              </div>
              <span v-if="errors.zip_code" class="error-message">{{ errors.zip_code }}<br></span>
            </label>
          </div>
          <div class="form__footer">
            <div class="error-message" v-if="responseError">{{ responseError }}</div>
            <button class="send-button form__btn btn"
                    :class="{'--loader-active': formSending}" type="submit">Отправить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showUserAddressFormPopupSuccess" class="popup">
    <div class="popup__overlay" @click="closeUserAddressFormPopupSuccess"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeUserAddressFormPopupSuccess">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg?v#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal-subtitle">Спасибо! Ваш адрес успешно заполнен🎉</div>
        <p><button class="send-button form__btn btn" @click="closeUserAddressFormPopupSuccess">Закрыть</button></p>
      </div>
    </div>
  </div>

  <!-- Popup статусов акции -->
  <div v-if="showPromoNotStarted" class="popup">
    <div class="popup__overlay" @click="closeShowPromoNotStarted"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeShowPromoNotStarted">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__points points text-center">
          <div class="modal-subtitle points__title">Акция еще не началась, но начнется {{ state.promoStart }}.<br>Следите за обновлениями! </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPromoEnd" class="popup">
    <div class="popup__overlay" @click="closeShowPromoEnd"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeShowPromoEnd">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__points points text-center">
          <h5 class="modal-title points__title">Акция завершена.
            <br>Спасибо за участие!</h5>
          <div class="modal-subtitle">
            Вручение призов осуществляется до&nbsp;31.12.2025&nbsp;г.
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для указания даты рождения -->
  <div v-if="showBirthdayPopup" class="popup">
    <div class="popup__overlay" @click="closeBirthdayPopup"></div>
    <div class="popup__window">
      <button @click="closeBirthdayPopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Укажите дату рождения</h5>
        <p class="modal-subtitle">Получите +20 баллов в подарок на свой день рождения!</p>
        <form class="form form-grid" @submit.prevent="submitBirthday">
          <div class="form__block-inputns">
            <label class="form__wrap">
              <div class="form__inner-input">
                <input v-model="birthday" class="form__input" type="text" @input="formatBirthdayInput" required>
              </div>
            </label>
          </div>
          <div class="form__footer">
            <div class="error-message" v-if="responseError">{{ responseError }}</div>
            <button :disabled="!birthday"
                    class="send-button btn"
                    :class="{'--loader-active': formSending}">Сохранить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showBirthdaySuccessPopup" class="popup">
    <div class="popup__overlay" @click="closeBirthdaySuccessPopup"></div>
    <div class="popup__window">
      <button @click="closeBirthdaySuccessPopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Готово</h5>
        <p class="modal-subtitle">Дата рождения добавлено</p>
        <div class="form form-grid">
          <button class="send-button btn" @click="closeBirthdaySuccessPopup">Закрыть</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для выбора приза -->
  <div v-if="showAvailablePrizesPopup" class="popup --truck">
    <div class="popup__overlay" @click="closeAvailablePrizesPopup"></div>
    <div class="popup__window">
      <button @click="closeAvailablePrizesPopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Выбрать подарок</h5>
        <div class="modal__prizes">
          <label v-for="item in availablePrizes" :key="item.id" class="radio">
            <input
                type="radio"
                name="prize"
                :value="item.id"
                class="radio__real-radio"
                v-model="selectedPrizeId"
                required
            />
            <span class="radio__custom-radio guaranteed-card">
              <div class="radio__custom-check"></div>
              <div class="guaranteed-card__image">
                <img v-if="item.id === 1"
                    class="full-img"
                    src="@/assets/img/garant/garant_1.webp"
                    srcset='@/assets/img/garant/garant_1@2x.webp 2x'
                    width="491"
                    height="152"/>
                <img v-else-if="item.id === 2"
                     class="full-img"
                     src="@/assets/img/garant/garant_2_1.png"
                     srcset='@/assets/img/garant/garant_2_1@2x.png 2x'
                     width="491"
                     height="152"/>
                <img v-else-if="item.id === 3"
                     class="full-img"
                     src="@/assets/img/garant/garant_3.webp"
                     srcset='@/assets/img/garant/garant_3@2x.webp 2x'
                     width="491"
                     height="152"/>
                <img v-else-if="item.id === 4"
                     class="full-img"
                     src="@/assets/img/garant/garant_4.webp"
                     srcset='@/assets/img/garant/garant_4@2x.webp 2x'
                     width="491"
                     height="152"/>
                <img v-else-if="item.id === 5"
                     class="full-img"
                     src="@/assets/img/garant/garant_5_1.png"
                     srcset='@/assets/img/garant/garant_5_1@2x.png 2x'
                     width="491"
                     height="152"/>
                <img v-else-if="item.id === 6"
                     class="full-img"
                     src="@/assets/img/garant/garant_6.webp"
                     srcset='@/assets/img/garant/garant_6@2x.webp 2x'
                     width="491"
                     height="152"/>
                <img v-else
                    class="full-img"
                    :src="item.image"
                    :alt="item.title"
                    width="491"
                    height="152"
                    :srcset="`${item.image} 2x`"
                />
              </div>
            </span>
          </label>
        </div>
        <div class="modal-contet__btn">
          <div class="error-message" v-if="responseError">{{ responseError }}</div>
          <button
              @click="submitPrize"
              class="send-button btn"
              :disabled="!selectedPrizeId">
            Выбрать подарок
          </button>
        </div>

      </div>
    </div>
  </div>

  <!-- Popup с информацией -->
  <div v-if="showInfoPopup" class="popup --truck">
    <div class="popup__overlay" @click="closeShowInfoPopup"></div>
    <div class="popup__window">
      <button @click="closeShowInfoPopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__track track"><h5 class="modal-title track__title">{{ this.info_title }}</h5>
          <div v-if="this.info_type==='electronic_certificate'" class="track__wrap-btn">
            <span>PDF-сертификат:</span>
            <a @click.prevent="downloadCertificate(this.info_id)" class="track__btn">
              <span>{{ this.info_number }}</span>
              <svg width="24" height="24">
                <use href="/img/icons.e261c3be.svg#download"></use>
              </svg>
            </a>
          </div>
          <div v-else-if="this.info_type==='promo_code'" class="track__wrap-btn">
            <span>Промокод:</span>
            <button class="track__btn" @click="copyToClipboard(this.info_number)">
              <span>{{ this.info_number }}</span>
              <svg width="24" height="24"><use href="/img/icons.e261c3be.svg#copy"></use></svg>
            </button>
          </div>
          <div class="track__content">
            {{ this.info_text }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup с подтверждением обмена v-if="showChangePopup" -->
  <div v-if="showChangePopup" class="popup">
    <div class="popup__overlay" @click="closeChangePopup"></div>
    <div class="popup__window">
      <button @click="closeChangePopup" class="modal-close">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
      </button>
      <div class="modal-content">
        <div class="modal__points points">
          <h5 class="modal-title points__title">Обменять баллы</h5>
          <div class="points__content">
            <p>Вы обмениваете {{this.changePoints}} баллов на {{this.changeTitle}}, согласны?</p>
          </div>
          <div class="error-message error-message--center --position-static" v-if="responseError">{{ responseError }}</div>
          <button class="btn" @click="confirmPrizePurchase(this.changeID)">
            Подтвердить
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Что-то скопировано -->
  <transition name="fade">
    <div v-if="showCopySuccessPopup" class="mini-message">
    <div class="mini-message-wrap">
        <div class="popup__window">
          <button @click="closeCopySuccessPopup" class="modal-close">
            <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg#x'></use></svg>
          </button>
          <div class="modal-content">
            <div class="modal__points points">
              <div class="points__content">
                Ссылка успешно скопирована.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <!-- Призов не осталось -->
  <div v-if="showEmptyPrizesPopup" class="popup">
    <div class="popup__overlay" @click="closeEmptyPrizesPopup"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeEmptyPrizesPopup">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg?v#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Упс, приз недоступен выбери другой.</h5>
        <div class="form form-grid">
          <button class="send-button btn" @click="closeEmptyPrizesPopup">Выбрать</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showChangePrizesPopup" class="popup">
    <div class="popup__overlay" @click="closeChangePrizesPopup"></div>
    <div class="popup__window">
      <button class="modal-close" @click="closeChangePrizesPopup">
        <svg width='40' height='40'><use href='@/assets/img/icons/icons.svg?v#x'></use></svg>
      </button>
      <div class="modal-content">
        <h5 class="modal-title">Упс, приз недоступен выбери другой.</h5>
        <div class="form form-grid">
          <button class="send-button btn" @click="closeChangePrizesPopup">Выбрать</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global qrWidget */
import {
  getMainSettings,
  getToken,
  getUserInfo,
  changeProfile,
  setAuthHeader,
  getUserChecks,
  changePassword,
  removeToken,
  sendUserAddress,
  updateProfile,
  fetchWinningPrizes,
  fetchAvailablePrizes,
  selectPrize,
  fetchAdditionalPrizeFile,
  fetchBalanceHistory,
  fetchAvailablePrizesFull,
  fetchAvailablePrizesGold,
  // fetchAvailablePrizesPlatinum,
  buyPrize
} from "@/api";
import { computed, nextTick } from "vue";
import { onClickOutside } from '@vueuse/core';
import { state } from "@/store";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import metrikaMixin from "@/mixins/metrikaMixin";

import Swiper from 'swiper';
import {Pagination } from 'swiper/modules';
import InfoBlock from '@/components/infoBlock.vue';
let swiperInstance = null;
let swiperInstance2 = null;

function initSwiperPrizesFull() {
  nextTick(() => {
    const nextButton = document.querySelector(".exchange-points__next");
    const prevButton = document.querySelector(".exchange-points__prev");

    // Проверяем, что кнопки навигации существуют
    if (nextButton && prevButton) {
      swiperInstance = new Swiper(".exchange-points__swiper", {
        modules: [Pagination],
        slidesPerView: 3,
        spaceBetween: 30,
        clickable: true,
        pagination: {
          el: ".exchange-points__pagination",
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: true,
          },
          360: {
            slidesPerView: 1.5,
            spaceBetween: 10,
            centeredSlides: true,
          },
          481: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          577: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          769: {
            slidesPerView: 4,
            spaceBetween: 37,
          },
        },
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
      });

      // Проверяем, что кнопки работают после инициализации
      nextButton.addEventListener("click", () => {
        swiperInstance.slideNext();
      });

      prevButton.addEventListener("click", () => {
        swiperInstance.slidePrev();
      });
    }
  });
}

function initSwiperPrizesGold() {
  // Пауза для полной загрузки данных
  nextTick(() => {
    const nextButton2 = document.querySelector(".exchange-points-sert__next");
    const prevButton2 = document.querySelector(".exchange-points-sert__prev");
    swiperInstance2 = new Swiper('.exchange-points-sert__swiper', {
      modules: [Pagination],
      slidesPerView: 3,
      spaceBetween: 30,
      clickable: true,
      pagination: {
        el: '.exchange-points-sert__pagination',
      },
      breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: true,
          },
          360: {
            slidesPerView: 1.6,
            spaceBetween: 10,
            centeredSlides: true,
          },
        481: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        577: {
          slidesPerView: 3,
          spaceBetween: 16
        },
        769: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        1201: {
          slidesPerView: 4,
          spaceBetween: 63
        },
      },
      navigation: {
        nextEl: nextButton2,
        prevEl: prevButton2,
      },
    });

    // Проверяем, что кнопки работают после инициализации
    nextButton2.addEventListener("click", () => {
      swiperInstance2.slideNext();
    });

    prevButton2.addEventListener("click", () => {
      swiperInstance2.slidePrev();
    });
  });
}

export default {
  name: 'PrivatePage',
  mixins: [metrikaMixin],
  components: {
    InfoBlock
  },
  setup() {
    const thumbWidth = computed(() => {
      const balance = state.user.balance;

      if (state.user.privilege_level == 'platinum') {
        return 100;
      } else if (state.user.privilege_level == 'gold') {
        if (state.user.need_points == '500') return 50;
        else {
          return (1000 - balance) / 100 + 50;
        }
      } else {
        if (balance == '0') {
          return 14;
        } else {
          return (500 - balance) / 100;
        }
      }
    });
    return {
      state,
      thumbWidth
    };
  },
  data() {
    return {
      isMenuOpen: false,
      isOpenDropdownMobile: false,
      selectedText: 'Мои баллы',
      items: [
        { text: 'ЗАГРУЖЕННЫЕ ЧЕКИ', action: 'openChecksTab' },
        { text: 'ИСТОРИЯ ОПЕРАЦИЙ', action: 'openHistoryTab' }
      ],
      formSending: false,
      changeTitle: null,
      changePoints: null,
      changeID: null,
      prizesFull: [],
      prizesGold: [],
      // prizesPlatinum: [],
      activeTab: 0,
      tabs: [
        { label: "Чеки" },
        { label: "История операций" }
      ],
      history: [],
      limit: 10,
      offset: 0,
      nextPage: null,
      pdfUrl: '',
      info_id: null,
      info_title: null,
      info_text: null,
      info_type: null,
      info_number: null,
      availablePrizes: [],
      selectedPrizeId: null,
      receiptId: '',
      birthday: '',
      checkInterval: null,
      checksTotal: 0,
      hasSpecificType: false,
      addressForm: {
        fio: '',
        phone_number: '',
        email: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        zip_code: '',
      },
      bonus_checks_count: null,
      isAddressFilled: false,
      isHeaderScrolled: false,
      scrollThreshold: 0,
      isMobileNavOpened: false,
      formData: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: ''
      },
      user: null,
      password: '',
      password_check: '',
      loginFormErrors: {},
      passwordVisible: false,
      rootUrl: window.location.origin,
      prizes: [],
      userPrizes: [],
      isOpen: [],
      errors: {},
      showEndPopupSuccess: false,
      showAccountChangePasswordPopup: false,
      showEmptyPrizesPopup: false,
      showBirthdayPopup: false,
      showInfoPopup: false,
      showChangePopup: false,
      showChangePrizesPopup: false,
      showAvailablePrizesPopup: false,
      showBirthdaySuccessPopup: false,
      showUserAddressFormPopup: false,
      showUserAddressFormPopupSuccess: false,
      showAccountDetailsPopup: false,
      showAccountDetailsSuccess: false,
      showAccountChangePasswordSuccess: false,
      showCopySuccessPopup: false,
      showPromoNotStarted: false,
      showPromoEnd: false,
      mainSettings: {},
      activeAccountSection: 0,
      scrolledAccountSection: -1,
      accountUser: {
        first_name: this.user && this.user.first_name ? this.user.first_name : '',
        last_name: this.last_name && this.user.last_name ? this.user.last_name : '',
        email: this.email && this.user.email ? this.user.email : '',
        phone_number: this.phone_number && this.user.phone_number ? this.user.phone_number : ''
      },
      responseError: '',
      isDisabled: true,
      showSaveCancelButtons: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      accountDetailsForm: {
        first_name: '',
        last_name: '',
        email: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 18,
      statusTextMap: {
        0: 'На проверке',
        arrived: 'На проверке',
        approved: 'Одобрен',
        rejected: 'Отклонен',
        won: 'Выиграл',
      },
      accountSections: [
        { name: 'Личные данные', component: 'PersonalData', scrolled: false },
        { name: 'Выигрынные призы', component: 'WinningPrizes', scrolled: false},
        { name: 'Загруженные чеки', component: 'QuizSection', scrolled: false },
      ],
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setAuthHeader();
    onClickOutside(this.$refs.dropdown, () => {
      this.isOpenDropdownMobile = false;
    });
    this.fetchPrizes();
    this.fetchPrizesGold();
    // this.fetchPrizesPlatinum();
    this.loadHistory();
    this.loadPrizes();
    Fancybox.bind("[data-fancybox]", {
    });
    this.initializeOpenState();
    this.fetchUsersCheck();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.accountUser = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
          };
          state.user = {
            address: {
              apartment: userInfo.address?.apartment ?? "",
              city: userInfo.address?.city ?? "",
              email: userInfo.address?.email ?? "",
              fio: userInfo.address?.fio ?? "",
              house: userInfo.address?.house ?? "",
              phone_number: userInfo.address?.phone_number ?? "",
              street: userInfo.address?.street ?? "",
              zip_code: userInfo.address?.zip_code ?? "",
            },
            bonus_checks_count: userInfo.bonus_checks_count,
            id: userInfo.id,
            uuid: userInfo.uuid,
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
            birthday: userInfo.birthday,
            is_possible_to_upload_receipt: userInfo.is_possible_to_upload_receipt,
            address_is_filled: userInfo.address_is_filled,
            promocode: userInfo.promocode,
            referral_id: userInfo.referral_id,
            referral_link: userInfo.referral_link,
            balance: userInfo.balance,
            privilege_level: userInfo.privilege_level,
            need_points: userInfo.need_points,
          }
          this.formData = { ...this.accountUser };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.userId = userInfo.id;
          this.userUuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.isAddressFilled = Boolean(userInfo.address_is_filled);
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    formattedBonusChecks() {
      const count = this.state.user.bonus_checks_count;

      // Проверка на null или undefined, если данные еще не загружены
      if (count === null || count === undefined) {
        return ''; // или вернуть текст вроде "Загрузка..."
      }

      if (count === 0) {
        return `0 шансов`;
      }

      const formattedCount = count > 0 ? `+${count}` : count;
      const chance = this.getChanceEnding(count);
      return `${formattedCount} ${chance}`;
    },
    dropdownStyle() {
      return this.isOpenDropdownMobile ? { top: 'calc(100% + 5px)' } : {};
    },
    tgBotUrl() {
      return `${process.env.VUE_APP_TG_BOT_URL}`;
    },
    rulesUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/rules`;
    },
    policyUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/policy`;
    },
    agreementUrl() {
      return `${process.env.VUE_APP_API_URL}/api/main/agreement`;
    },
    isFormValid() {
      return (
          this.password.length >= 6 &&
          this.password_check.length >= 6 &&
          this.password === this.password_check
      );
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    validateCyrillic(event) {
      let value = event.target.value.replace(/[^а-яёА-ЯЁ\s-]/g, '');
      this.addressForm.fio = value.replace(/^\s+/, ''); // Убираем пробел в начале
    },
    getChanceEnding(count) {
      const lastDigit = count % 10;
      const lastTwoDigits = count % 100;

      if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return 'шансов'; // Исключение для чисел 11-14
      }

      switch (lastDigit) {
        case 1:
          return 'шанс';
        case 2:
        case 3:
        case 4:
          return 'шанса';
        default:
          return 'шансов';
      }
    },
    async copyReferralLink() {
      const text = `Присоединяйся к GREENFIELD CLUB! Переходи по моей ссылке ${this.state.user.referral_link}, регистрируйся с промокодом ${this.state.user.referral_id} и получи 10 приветственных баллов`;

      try {
        await navigator.clipboard.writeText(text);
        this.showCopySuccessPopup = true;
        setTimeout(() => {
          this.showCopySuccessPopup = false;
        }, 1300);
      } catch (err) {
        console.log(err);
      }
    },
    formatPhone(phone) {
      return phone ? phone.replace(/(\+7)(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5') : ''
    },
    handleDynamicButtonClick(event) {
      if (event.target.id === 'apm-close-widget-button') {
        state.isUploadOpened = false;
        this.$sendMetrik('reachGoal', 'close_load');
        // this.executeAdriverEvent('close_load');
        // Логика обработки клика
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.toggleBodyScroll();
    },
    closeMenu() {
      this.isMenuOpen = false;
      this.toggleBodyScroll();
    },
    toggleBodyScroll() {
      if (this.isMenuOpen) {
        document.body.classList.add('noscroll');
      } else {
        document.body.classList.remove('noscroll');
      }
    },
    toggleDropdown() {
      this.isOpenDropdownMobile = !this.isOpenDropdownMobile;
    },
    scrollToSection(sectionId) {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        this.isOpenDropdownMobile = false;
      }
    },
    callAction(action) {
      if (action === 'openChecksTab') {
        this.activeTab = 0;
        this.scrollToSection('#checks');
      } else if (action === 'openHistoryTab') {
        this.activeTab = 1;
        this.scrollToSection('#checks');
      }
    },
    selectItem(item) {
      this.selectedText = item.text;
      this.isOpenDropdownMobile = false;
      if (item.action) {
        this.callAction(item.action);
      }
    },
    openHistoryTab() {
      this.activeTab = 1; // Меняем вкладку
      this.$nextTick(() => {
        const target = document.querySelector("#checks");
        if (target) {
          target.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    },
    openChecksTab() {
      this.activeTab = 0; // Меняем вкладку
      this.$nextTick(() => {
        const target = document.querySelector("#checks");
        if (target) {
          target.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    },
    copyToClipboard(text) {
      // Пытаемся скопировать информацию в буфер обмена
      navigator.clipboard.writeText(text)
          .then(() => {
            alert('Скопировано');
          })
          .catch(err => {
            console.error('Ошибка при копировании: ', err);
          });
    },
    reload() {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.accountUser = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
          };
          state.user = {
            address: {
              apartment: userInfo.address.apartment,
              city: userInfo.address.city,
              email: userInfo.address.email,
              fio: userInfo.address.fio,
              house: userInfo.address.house,
              phone_number: userInfo.address.phone_number,
              street: userInfo.address.street,
              zip_code: userInfo.address.zip_code,
            },
            bonus_checks_count: userInfo.bonus_checks_count,
            id: userInfo.id,
            uuid: userInfo.uuid,
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
            birthday: userInfo.birthday,
            is_possible_to_upload_receipt: userInfo.is_possible_to_upload_receipt,
            address_is_filled: userInfo.address_is_filled,
            promocode: userInfo.promocode,
            referral_id: userInfo.referral_id,
            referral_link: userInfo.referral_link,
            balance: userInfo.balance,
            privilege_level: userInfo.privilege_level,
            need_points: userInfo.need_points,
          }
          this.formData = { ...this.accountUser };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.userId = userInfo.id;
          this.userUuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.isAddressFilled = Boolean(userInfo.address_is_filled);
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
      this.fetchPrizes();
      this.fetchPrizesGold();
      // this.fetchPrizesPlatinum();
      this.loadHistory();
      this.loadPrizes();
      this.loadPrizes();
    },
    async confirmPrizePurchase(prizeId) {
      try {
        const response = await buyPrize(prizeId); // Отправляем запрос на покупку
        this.showChangePopup = false;
        this.removeBodyClass();
        this.reload();
        console.log(response);
      } catch (error) {
        if (error.response.data.prize && error.response.data.prize[0] === 'приз не найден или более недоступен') {
          this.showChangePrizesPopup = true;
        } else {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async fetchPrizes() {
      try {
        const data = await fetchAvailablePrizesFull();
        this.prizesFull = data.results;
        initSwiperPrizesFull();
      } catch (error) {
        console.error('Error fetching prizes:', error);
      }
    },
    async fetchPrizesGold() {
      try {
        const data = await fetchAvailablePrizesGold();
        this.prizesGold = data.results;
        initSwiperPrizesGold();
      } catch (error) {
        console.error('Error fetching prizes:', error);
      }
    },
    // async fetchPrizesPlatinum() {
    //   try {
    //     const data = await fetchAvailablePrizesPlatinum;
    //     this.prizesPlatinum = data.results;
    //   } catch (error) {
    //     console.error('Error fetching prizes:', error);
    //   }
    // },
    getOperationLabel(type) {
      const labels = {
        points_per_items_in_receipt: "Начислено баллов за чек",
        birthday_bonus: "Подарок на день рождения от Greenfield!",
        referral_bonus: "Приветственные баллы за присоединение к Greenfield CLUB",
        manual_points: "Вам были начислены баллы",
        write_off_for_prize: "Заказ: ",
      };
      return labels[type] || type;
    },
    async loadHistory() {
      try {
        const data = await fetchBalanceHistory(this.limit, this.offset);
        this.history.push(...data.results);
        this.nextPage = data.next;
        this.offset += this.limit;
      } catch (error) {
        console.error("Failed to load balance history:", error);
      }
    },
    async loadMore() {
      await this.loadHistory();
    },
    formatDateBalance(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString("ru-RU", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" });
    },
    formatAmount(amount) {
      return amount > 0 ? `+${amount}` : amount;
    },
    async downloadCertificate(id) {
      try {
        const response = await fetchAdditionalPrizeFile(id);
        // Создаём ссылку для скачивания файла
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Определяем имя файла
        const filename = response.headers['content-disposition']
            ? response.headers['content-disposition'].split('filename=')[1]
            : `certificate-${id}.pdf`;

        link.setAttribute('download', filename.replace(/"/g, '')); // Убираем кавычки
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Failed to download certificate:", error);
      }
    },
    async loadPrizes() {
      try {
        const response = await fetchWinningPrizes();
        this.prizes = response.results;
      } catch (error) {
        console.error('Failed to load prizes:', error);
      }
    },
    getPrizeType(prize) {
      if (!prize) return '';
      switch (prize.type) {
        case 'guaranteed':
          return 'Гарантированный';
        case 'weekly':
          return 'Еженедельный';
        case 'monthly':
          return 'Ежемесячный';
        case 'grand':
          return 'Главный приз';
        case 'special':
          return 'Специальный';
        case 'point':
          return 'Накопительный';
        default:
          return '';
      }
    },
    // Загрузка доступных призов
    async loadAvailablePrizes() {
      try {
        const response = await fetchAvailablePrizes();
        this.availablePrizes = response.results; // Сохраняем доступные призы в data
      } catch (error) {
        this.responseError = 'Не удалось загрузить доступные призы';
      }
    },

    // Метод для открытия попапа и загрузки доступных призов
    selectPrize(receiptId) {
      this.receiptId = receiptId;
      this.addBodyClass();
      this.showAvailablePrizesPopup = true;
      this.responseError = '';
      this.loadAvailablePrizes();
    },

    // Отправка выбранного приза
    async submitPrize() {
      if (!this.selectedPrizeId) {
        this.responseError = 'Пожалуйста, выберите приз';
        return;
      }

      try {
        await selectPrize(this.receiptId, this.selectedPrizeId);
        this.showAvailablePrizesPopup = false;
        this.removeBodyClass();
        this.$emit('prize-selected', this.selectedPrizeId);
        this.loadPrizes();
      } catch (error) {
        if (error.response.data.prize && error.response.data.prize[0] === 'приз не найден или более недоступен') {
          this.showEmptyPrizesPopup = true;
        } else {
          this.responseError = error.response.data.detail;
        }
      }
    },
    formatBirthdayInput(event) {
      let value = event.target.value.replace(/\D/g, ''); // Оставляем только цифры

      // Ограничиваем длину до 8 символов
      if (value.length > 8) {
        value = value.slice(0, 8);
      }

      // Форматируем строку в DD-MM-YYYY
      let formattedValue = value;

      if (value.length >= 2) {
        formattedValue = value.slice(0, 2) + '-' + value.slice(2); // Добавляем дефис после двух первых цифр
      }
      if (value.length >= 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5); // Добавляем дефис после пяти символов
      }

      // Удаляем дефис, если длина строки меньше 5 или 2
      if (formattedValue.length < 5) {
        formattedValue = formattedValue.replace('-', '');
      }

      if (formattedValue.length < 2) {
        formattedValue = formattedValue.replace('-', '');
      }

      // Присваиваем форматированное значение в форму
      this.birthday = formattedValue;
    },
    convertBirthdayFormat(date) {
      if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
      }
      return date;
    },
    async submitBirthday() {
      try {
        this.formSending = true;
        const formattedBirthday = this.convertBirthdayFormat(this.birthday);
        await updateProfile({
          first_name: state.user.first_name,
          last_name: state.user.last_name,
          email: state.user.email,
          phone_number: state.user.phone_number,
          birthday: formattedBirthday
        });
        this.closeBirthdayPopup();
        this.openBirthdaySuccessPopup();
        state.user = {
          first_name: state.user.first_name,
          last_name: state.user.last_name,
          email: state.user.email,
          phone_number: state.user.phone_number,
          birthday: this.birthday
        };
        this.formSending = false;
      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          if (error.response.data.birthday[0] === 'Неправильный формат date. Используйте один из этих форматов: YYYY-MM-DD.') {
            this.responseError = 'Неправильный формат. Введите в формате: ДД-ММ-ГГГГ';
          } else {
            this.responseError = error.response.data.birthday[0];
          }
        }
      }
    },
    // executeAdriverEvent(page) {
    //   const sidMapping = {
    //     faq: 'click_faq',
    //     prizes: 'click_prizes',
    //     winners: 'click_winners',
    //     close_registration: 'abandoned_user_registration_close_form',
    //     close_load: 'abandoned_check_registration_close_form',
    //     click_greenfield: 'click_greenfield',
    //     click_jardin: 'click_jardin',
    //     click_magnit: 'click_magnit',
    //     click_home: 'click_main',
    //     upload_check_open: 'click_register_check',
    //     click_how_use: 'click_how_participate',
    //     click_account: 'click_lk',
    //     registration_success: 'successful_user_registration',
    //     timer_15: 'timer_15'
    //   };
    //
    //   if (!sidMapping[page]) return;
    //
    //   const sid = 229236; // ваш статический sid
    //   const bt = 62; // ваш статический bt
    //   const sz = sidMapping[page];
    //
    //   // Вызов Adriver-кода
    //   (function (e, n) {
    //     function o(e, n, o) {
    //       n = n || "&";
    //       o = o || "=";
    //       var d = [];
    //       for (var r in e)
    //         if (Object.prototype.hasOwnProperty.call(e, r)) {
    //           d.push(r + o + encodeURIComponent(e[r]));
    //         }
    //       return d.join(n);
    //     }
    //     function d(e, n) {
    //       var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
    //       return o ? decodeURIComponent(o[2]) : null;
    //     }
    //     var r,
    //         t,
    //         i,
    //         c,
    //         u;
    //     (r = e),
    //         (t = n),
    //         (i = document.domain),
    //         (c = { tail256: document.referrer || "unknown" }),
    //     void 0 !==
    //     (u = (function (e) {
    //       var n = {};
    //       if (e) {
    //         var o = e.split("&");
    //         for (var d in o)
    //           if (Object.prototype.hasOwnProperty.call(o, d)) {
    //             var r = o[d].split("=");
    //             void 0 !== r[0] &&
    //             void 0 !== r[1] &&
    //             (n[r[0]] = decodeURIComponent(r[1]));
    //           }
    //       }
    //       return n;
    //     })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
    //     null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")),
    //     t &&
    //     t.id &&
    //     null !== d(document, t.id) &&
    //     (r.suid = i + "_" + encodeURIComponent(d(document, t.id))),
    //         t && t.gid1
    //             ? (r.gid1 = t.gid1)
    //             : null !== d(document, "_ga") &&
    //             (r.gid1 = encodeURIComponent(d(document, "_ga"))),
    //         t && t.yid1
    //             ? (r.yid1 = t.yid1)
    //             : null !== d(document, "_ym_uid") &&
    //             (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))),
    //         (r.loc = encodeURIComponent(window.location.href)),
    //     r.custom && (r.custom = o(r.custom, ";")),
    //         (function (e, n) {
    //           !(function (e) {
    //             if (
    //                 ((e = e.split("![rnd]").join(~~(1e6 * Math.random()))),
    //                 document.createElement && document.body)
    //             ) {
    //               var n = document.createElement("img");
    //               (n.style.position = "absolute"),
    //                   (n.style.display = "none"),
    //                   (n.style.width = n.style.height = "0px"),
    //                   n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
    //                   (n.src = e),
    //                   document.body.appendChild(n);
    //             } else {
    //               var o = new Image();
    //               o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
    //                   (o.src = e);
    //             }
    //           })(
    //               "https://ad.adriver.ru/cgi-bin/rle.cgi?" +
    //               e +
    //               "&rnd=![rnd]" +
    //               (n ? "&" + n : "")
    //           );
    //         })(o(r), o(c));
    //   })({ sid: sid, bt: bt, sz: sz }, { id: "", gid1: "", yid1: "" });
    // },
    // executeDSPCounterEvent(siteArea) {
    //   const sid = 229303; // ваш статический sid
    //   const siteAreaMapping = {
    //     '30_sec': 'TJCuegy2',
    //     'login_success': 'qh1jImpT',
    //     'check_upload_success': 'hAf9oUO0',
    //     'buttons_click': 'bG46a4ie',
    //     'faq_click': 'USZY4vWn',
    //   };
    //
    //   const siteAreaId = siteAreaMapping[siteArea] || 'USZY4vWn';
    //
    //
    //   // Проверка и отложенный вызов если DSPCounter еще не загружен
    //   const checkDSPCounter = setInterval(() => {
    //     if (window.DSPCounter) {
    //       clearInterval(checkDSPCounter);
    //       window.DSPCounter('send', {
    //         'sid': sid,
    //         'site_area': siteAreaId,
    //         'user_id': '',
    //         'lead_id': '',
    //         'order_sum': ''
    //       });
    //     }
    //   }, 100); // проверяем каждые 100ms
    // },
    SendMetrick(page) {
      if (page == 'how-register') {
        this.$sendMetrik('reachGoal', 'click_how_use');
        // this.executeAdriverEvent('click_how_use');
      } else if (page == 'faq') {
        this.$sendMetrik('reachGoal', 'click_faq');
        // this.executeAdriverEvent('faq');
      } else if (page == 'prizes') {
        this.$sendMetrik('reachGoal', 'click_prize');
        // this.executeAdriverEvent('prizes');
      } else if (page == 'winners') {
        this.$sendMetrik('reachGoal', 'click_winners');
        // this.executeAdriverEvent('winners');
      }
    },
    startCheckingForNewChecks() {
      // Если уже идет проверка, не запускаем ещё раз
      if (this.checkInterval) return;

      this.checkInterval = setInterval(async () => {
        try {
          const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

          if (checksData.count && checksData.count !== state.checksTotal) {
            // Обновляем данные, если количество чеков изменилось
            state.checksTotal = checksData.count;
            state.checks.results = checksData.results;

            // Останавливаем таймер
            this.stopCheckingForNewChecks();

            // Очищаем флаг ожидания
            state.checkSoon = false;
          }
        } catch (error) {
          console.error('Error checking for new checks:', error);
        }
      }, 5000); // Проверяем каждые 5 секунд
    },
    stopCheckingForNewChecks() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
    filterZipCode() {
      this.addressForm.zip_code = this.addressForm.zip_code.replace(/\D/g, '');
    },
    async sendUserAddressForm() {
      try {
        this.formSending = true;
        const { fio, phone_number, email, city, street, house, apartment, zip_code } = this.addressForm;

        // Форматирование номера телефона (пример, если необходимо)
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Очистка ошибок перед проверкой
        this.errors = {};

        // Валидация каждого поля
        if (fio.length < 3) {
          this.errors.fio = 'ФИО должно быть не менее 3 символов.';
        }
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный номер телефона.';
        }

        // Пример проверки email на корректность
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Проверка на пустой email и корректный формат
        if (email.trim() === '') {
          this.errors.email = 'Поле не может быть пустым.';
        } else if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email.';
        }

        // Проверка почтового индекса на длину
        if (zip_code.length !== 6) {
          this.errors.zip_code = 'Почтовый индекс должен содержать 6 цифр.';
        }

        // Проверка обязательных полей (например, город, улица и дом)
        if (!city) {
          this.errors.city = 'Город обязательно';
        }
        if (!street) {
          this.errors.street = 'Улица обязательно';
        }
        if (!house) {
          this.errors.house = 'Дом обязателен';
        }
        if (!apartment) {
          this.errors.apartment = 'Номер квартиры обязателен';
        }

        // Если есть ошибки, не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          this.formSending = false;
          return;
        }

        // Отправка данных с уже отформатированным номером
        const payload = {
          fio,
          phone_number: formattedPhoneNumber,
          email,
          city,
          street,
          house,
          apartment,
          zip_code
        };

        // Здесь можно вызвать функцию для отправки данных
        await sendUserAddress(payload);

        // Если все прошло успешно
        this.isAddressFilled = Boolean(true);
        this.showUserAddressFormPopupSuccess = true;
        this.showUserAddressFormPopup = false;
        this.formSending = false;
        // Очистить форму или выполнить другие действия после успешной отправки
        const token = getToken();
        if (token) {
          getUserInfo().then(userInfo => {
            if (userInfo) {
              state.user = {
                address: {
                  apartment: userInfo.address?.apartment ?? "",
                  city: userInfo.address?.city ?? "",
                  email: userInfo.address?.email ?? "",
                  fio: userInfo.address?.fio ?? "",
                  house: userInfo.address?.house ?? "",
                  phone_number: userInfo.address?.phone_number ?? "",
                  street: userInfo.address?.street ?? "",
                  zip_code: userInfo.address?.zip_code ?? "",
                },
                bonus_checks_count: userInfo.bonus_checks_count,
                id: userInfo.id,
                uuid: userInfo.uuid,
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                phone_number: userInfo.phone_number,
                email: userInfo.email,
                birthday: userInfo.birthday,
                is_possible_to_upload_receipt: userInfo.is_possible_to_upload_receipt,
                address_is_filled: userInfo.address_is_filled,
                promocode: userInfo.promocode,
                referral_id: userInfo.referral_id,
                referral_link: userInfo.referral_link,
                balance: userInfo.balance,
                privilege_level: userInfo.privilege_level,
                need_points: userInfo.need_points,
              }
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.isAddressFilled = Boolean(userInfo.address_is_filled);
            }
          }).catch(error => {
            console.error('Failed to fetch user info on mount', error);
          });
        }
      } catch (error) {
        this.formSending = false;
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    clearError(field) {
      this.errors[field] = '';
    },

    initQrWidget() {
      if (!document.getElementById('apm-scan-qr')) {
        qrWidget.init('apm-widget', {
          api: 'https://api.apmcheck.ru',
          apiKey: 'b6bfe0bb-cdfb-4d32-900e-d9095711da65',
          userUuid: this.userUuid,
          callbacks: {
            onReceiptSentSuccess: () => {
              this.$sendMetrik('reachGoal', 'load_success');
              this.$sendAdRiverMetrik('receipt-sent');
              // this.executeAdriverEvent('check_upload_success');
              // this.executeDSPCounterEvent('check_upload_success');
              state.checkSoon = true;
              state.isUploadOpened = false;
              this.startCheckingForNewChecks();
            },
            onReceiptSentError: () => {
              state.isUploadOpened = false;
            }
          }
        });
      }
    },
    redirectToPrivatePage() {
      this.$sendMetrik('reachGoal', 'click_account');
      // this.executeAdriverEvent('click_account');
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    handleRulesButtonClick() {
      this.$sendMetrik('reachGoal', 'rules');
    },
    async logout() {
      state.checkSoon = false;
      state.promoStatus = '';
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.user = {
        address: {
          apartment: null,
          city: null,
          email: null,
          fio: null,
          house: null,
          phone_number: null,
          street: null,
          zip_code: null,
        },
        bonus_checks_count: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        birthday: null,
        id: null,
        uuid: null,
        is_possible_to_upload_receipt: null,
        address_is_filled: null,
        promocode: null,
        referral_id: null,
        referral_link: null,
        balance: null,
        privilege_level: null,
        need_points: null
      };
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      window.location.href = '/';
    },
    openLoginPopup() {
      if (state.promoStatus == 'start' || state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showLoginPopup = true;
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showPromoNotStarted = true;
      }
    },
    handleCombinedClick(target) {
      this.toggleMobileNav();
      this.SendMetrick(target);
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    redirectToHomePage(page) {
      if (page == 'link') {
        this.$sendMetrik('reachGoal', 'click_home');
        // this.executeAdriverEvent('click_home');
      }
      this.$router.push('/');
    },
    logoLinkClick(page) {
      if (page == 'magnit') {
        this.$sendMetrik('reachGoal', 'click_magnit');
      } else if (page == 'jardin') {
        this.$sendMetrik('reachGoal', 'click_jardin');
        // this.executeAdriverEvent('click_jardin');
      } else if (page == 'green') {
        this.$sendMetrik('reachGoal', 'click_greenfield');
        // this.executeAdriverEvent('click_greenfield');
      }
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    loadMoreChecks() {
      if (state.checks.next) {
        this.checkOffset += 18;
        this.fetchUsersCheck();
      }
    },
    initializeOpenState() {
      this.isOpen = [...this.prizes, ...state.checks.results].map(() => false);
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    },
    openBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = true; // Установка состояния для открытия блока
      });
    },
    closeBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = false; // Установка состояния для закрытия блока
      });
    },
    getImageSrc(imageUrl) {
      // You can add any logic here to manipulate or format the image URL if needed
      return imageUrl;
    },
    getStatusText(status) {
      return this.statusTextMap[status] || 'Unknown';
    },
    getStatusClass(status) {
      return {
        'status-loaded': status === 0,
        'status-pending': status === 1,
        'status-approved': status === 3,
        'status-rejected': status === 4,
        'status-won': status === 5,
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    triggerQrScan() {
      // eslint-disable-next-line no-undef
      const img = document.createElement("img");
      img.src = "https://bu--s77.sync.t2.ru";
      img.width = 0;
      img.height = 0;
      img.alt = "";
      img.style.border = "0";
      img.style.position = "absolute";
      img.style.visibility = "hidden";
      document.body.appendChild(img);
      img.onload = img.onerror = () => {
        setTimeout(() => img.remove(), 3000); // Удаляем через 5 секунд
      };
      // this.executeDSPCounterEvent('buttons_click');
      if (state.promoStatus == 'start') {
        const qrElement = document.getElementById('apm-scan-qr');
        if (qrElement) {
          // this.$sendMetrik('reachGoal', 'upload_check_open');
          // this.executeAdriverEvent('upload_check_open');
          state.isUploadOpened = true;
          qrElement.click();
          this.$sendAdRiverMetrik('upload-receipt-lc');
          this.$sendMetrik('reachGoal', 'upload-receipt-lc');
        }
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      } else if (state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoEnd = true;
      }
    },
    triggerQuestionForm() {
      const qrElement = document.getElementById('triggerQuestionForm');
      if (qrElement) {
        qrElement.click();
      }
    },
    async fetchUsersCheck() {
      try {
        const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

        if (checksData.count) {
          state.checksTotal = checksData.count;
        }

        // Добавляем новые чеки к существующим
        state.checks.results.push(...checksData.results);

        // Обновляем значение next
        state.checks.next = checksData.next;

      } catch (error) {
        console.error('Error fetching checks:', error);
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.last_name = input;
    },
    openAccountChangePasswordPopup() {
      this.addBodyClass();
      this.showAccountChangePasswordPopup = true;
      this.responseError = '';
    },
    openBirthdayPopup() {
      this.addBodyClass();
      this.showBirthdayPopup = true;
      this.responseError = '';
    },
    closeBirthdayPopup() {
      this.removeBodyClass();
      this.showBirthdayPopup = false;
    },
    closeAvailablePrizesPopup() {
      this.removeBodyClass();
      this.showAvailablePrizesPopup = false;
    },
    openBirthdaySuccessPopup() {
      this.addBodyClass();
      this.showBirthdaySuccessPopup = true;
      this.responseError = '';
    },
    closeBirthdaySuccessPopup() {
      this.removeBodyClass();
      this.showBirthdaySuccessPopup = false;
      this.responseError = '';
    },
    closeAccountChangePasswordPopup() {
      this.removeBodyClass();
      this.showAccountChangePasswordPopup = false;
    },
    closeEmptyPrizesPopup() {
      this.selectPrize(this.receiptId);
      this.showEmptyPrizesPopup = false;
    },
    closeChangePrizesPopup() {
      this.fetchPrizes();
      this.showChangePopup = false;
      this.showChangePrizesPopup = false;
      this.removeBodyClass();
    },
    openAddressPopup() {
      this.addBodyClass();
      this.showUserAddressFormPopup = true;
      this.responseError = '';
    },
    closeUserAddressFormPopup() {
      this.removeBodyClass();
      this.showUserAddressFormPopup = false;
      this.responseError = '';
    },
    openShowInfoPopup(text,title,type,number,id) {
      this.info_id = id;
      this.info_number = number;
      this.info_type = type;
      this.info_title = title;
      this.info_text = text;
      this.addBodyClass();
      this.showInfoPopup = true;
      this.responseError = '';
    },
    closeShowInfoPopup() {
      this.removeBodyClass();
      this.showInfoPopup = false;
      this.responseError = '';
    },
    closeCopySuccessPopup() {
      this.removeBodyClass();
      this.showCopySuccessPopup = false;
      this.responseError = '';
    },
    closeUserAddressFormPopupSuccess() {
      this.removeBodyClass();
      this.showUserAddressFormPopupSuccess = false;
      this.responseError = '';
    },
    openAccountDetailsPopupSuccess() {
      this.addBodyClass();
      this.showAccountDetailsSuccess = true;
      this.responseError = '';
    },
    closeEndPopupSuccess() {
      this.removeBodyClass();
      this.showEndPopupSuccess = false;
      this.responseError = '';
    },
    openAccountChangePasswordPopupSuccess() {
      this.addBodyClass();
      this.showAccountChangePasswordSuccess = true;
      this.responseError = '';
    },
    closeAccountDetailsSuccess() {
      this.removeBodyClass();
      this.showAccountDetailsSuccess = false;
    },
    openChangePopup(title,id,points) {
      this.changeTitle = title;
      this.changeID = id;
      this.changePoints = points;
      this.addBodyClass();
      this.showChangePopup = true;
      this.responseError = '';
    },
    closeChangePopup() {
      this.removeBodyClass();
      this.showChangePopup = false;
      this.responseError = '';
    },
    closeAccountChangePasswordSuccess() {
      this.removeBodyClass();
      this.showAccountChangePasswordSuccess = false;
    },
    closeShowPromoNotStarted() {
      this.removeBodyClass();
      this.showPromoNotStarted = false;
    },
    closeShowPromoEnd() {
      this.removeBodyClass();
      this.showPromoEnd = false;
    },
    async changeAccountPassword() {
      try {
        const { password, password_check } = this;

        // Проверка полей
        this.errors = {};
        if (password.length < 6) {
          this.errors.password = 'Пароль должен быть не менее 6 символов';
        }
        if (password_check.length < 6) {
          this.errors.password_check = 'Подтверждение пароля должно быть не менее 6 символов';
        }
        if (password !== password_check) {
          this.errors.password_check = 'Пароли не совпадают';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение пароля
        await changePassword(password);

        // Успешное завершение действий, например, закрытие модального окна или уведомление
        this.closeAccountChangePasswordPopup();
        this.openAccountChangePasswordPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async changeAccountDetails() {
      try {
        const { first_name, last_name, email, phone_number } = this.formData;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }

        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение данных (например, через API)
        await changeProfile(first_name, last_name, email, phone_number);

        // Если запрос успешен, обновляем accountUser
        this.accountUser = { ...this.formData };

        this.isDisabled = true;
        this.showSaveCancelButtons = false;
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
  },
  beforeUnmount() {
    // Очищаем таймер при разрушении компонента
    this.stopCheckingForNewChecks();
    document.removeEventListener('click', this.handleDynamicButtonClick);
  },
  async created() {
    try {
      const [mainSettingsData] = await Promise.all([
        getMainSettings()
      ]);
      this.mainSettings = mainSettingsData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
};
</script>